/* eslint-disable import/no-extraneous-dependencies */
import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const useProviderAuth = () => {
  const navigate = useNavigate();
  const accessToken = Cookies.get("token");

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken]);

  const signin = (tokens, userdata, callback = null) => {
    const { token, redirect } = tokens || {};
    const { campaignId } = userdata || {};
    Cookies.set("token", token);
    Cookies.set("redirect", redirect);
    Cookies.set("campaignId", campaignId || "1");
    callback();
  };

  const signout = () => {
    Cookies.remove("token");
    Cookies.remove("campaignId");
    Cookies.remove("redirect");
    navigate("/login");
  };

  return {
    accessToken,
    signin,
    signout,
  };
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ProvideAuth = ({ children }) => {
  const auth = useProviderAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
