/* eslint-disable no-plusplus */
/* eslint-disable prefer-regex-literals */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable object-curly-newline */
import { Button, Flex, Form, Radio, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import dayjs from "dayjs";
import useHookProviderUpload from "../common/hooks/useProviderUpload";
import ImageUpload from "../common/components/UploadImage";
import {
  setIsOpenModalError,
  setTitleModalError,
} from "../common/components/ModalError/modalErrorSlice";
import EditSentiment from "../features/sentiment";
import {
  StyleInputForm,
  ThemeStyledTextArea,
  ThemeStyledInputNumber,
  ThemeStyledSelect,
  StyledRadio,
} from "../common/components/ThemeStyledComponent";
import ModalConfirmComponent from "../common/components/ModalComponent";
import { ThemeStyledInput } from "../features/keyword/inputKeyword";
import MainLayout from "../common/layouts/main";
import keywordProvider from "../features/keyword/provider/keywordProvider";
import ListTrendProvider from "../features/listTrend/provider/listTrendProvider";
import {
  options,
  arrowOptions,
  sentimentOptions,
  mapSentimentValue,
  sentimentTypeColor,
  optionsFilterStatus,
  importImageOption,
} from "../features/sentiment/optionsData";

const ButtonConfirm = styled(Button)`
  background-color: #7d5de8;
  width: 180px;
  /* &:hover {
    background:  !important;
  } */
`;

const SentimentRedio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 98px;
  height: 48px;
  border-radius: 10px;
  padding: 0px 16px;
  font-weight: 700;
`;

const RadioGroupCampaign = styled(Radio.Group)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
`;

const FormItem = styled(Form.Item)`
  > div {
    align-items: center;
  }
  .ant-form-item-control-input {
    width: ${(props) => (props?.width ? props?.width : "100%")};
  }
`;

const TitleBlockImage = styled.div`
  width: 12.5%;
`;

const InputForm = () => {
  const dispatch = useDispatch();
  const listTrendProvider = ListTrendProvider();
  const keywordProviders = keywordProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const [formTrend] = Form.useForm();
  const [imageType, setImageType] = useState("noImage");
  const { state = { type: "add" } } = location;
  const [isInsert, setIsInsert] = useState(true);
  const [mainKeyword, setMainKeyword] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSentimentModalOpen, setIsSentimentModalOpen] = useState(false);
  const {
    images,
    previews,
    progress,
    error,
    fileInputRef,
    handleFileChange,
    handleRemoveImage,
    uploadFile,
    setPreviews,
    setImages,
  } = useHookProviderUpload();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);

    const redirectUrl = state?.page
      ? `/list-post/${state?.current_campaign_id}/${state?.page}`
      : `/list-post/${state?.current_campaign_id}`;
    navigate(redirectUrl);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onUploadComplete = (res) => {
    return res;
  };

  const handleSubmit = async (val) => {
    let body = {};
    try {
      /// upload first
      // event.preventDefault();
      if (val.imageImport === "noImage") {
        body = {
          ...body,
          image_path: null,
          image_url: null,
        };
      } else if (val.imageImport === "upload") {
        if (images[0] instanceof File) {
          const resUpload = await uploadFile();
          if (!resUpload) return;
          body = {
            ...body,
            image_path: resUpload.image_path,
            image_url: null,
          };
        } else {
          body = {
            ...body,
            image_path: val.image_path,
            image_url: null,
          };
        }
      } else if (val.imageImport === "import") {
        body = {
          ...body,
          image_url: [val.imageURL],
          image_path: null,
        };
      }
      // if (true || res.data) {
      //   onUploadComplete(res);
      // }
      /// upload first

      body = {
        ...body,
        title: val.title,
        poster: val.poster,
        description: val.description ? val.description : "",
        url: val.url,
        platform: val.platform,
        likes: val.likes,
        shares: val.shares,
        views: val.views,
        comments: val.comments,
        campaign_id: val.campaign_id,
        keyword: val.keyword,
        arrow: val.arrow,
        approved: val?.status,
      };
      const mappedSentimentData = mapSentimentValue(val?.sentiment);

      if (state?.type === "add" && val?.sentiment) {
        body.positive_trend = mappedSentimentData.positive_trend;
        body.negative_trend = mappedSentimentData.negative_trend;
      }

      if (state?.type === "add" && !val?.sentiment) {
        body.positive_trend = 0;
        body.negative_trend = 0;
      }

      if (val?.post_date) {
        body.post_date = dayjs(val?.post_date).format("YYYY-MM-DD HH:mm:ss");
      }

      if (state?.type === "add") {
        await listTrendProvider.addListTrend(body);
      } else {
        await listTrendProvider.editListTrend(state?.trendId, body);
      }

      const redirectUrl = state?.page
        ? `/list-post/${state?.current_campaign_id}/${state?.page}`
        : `/list-post/${state?.current_campaign_id}`;
      navigate(redirectUrl);
    } catch (errors) {
      dispatch(setTitleModalError({ value: "เกิดข้อผิดพลาด" }));
      dispatch(setIsOpenModalError({ value: true }));
    }
  };

  const setEditValue = () => {
    for (const [key, val] of Object.entries(state)) {
      formTrend.setFieldValue(key, val);
      if (key === "sentiment") {
        formTrend.setFieldValue("sentiment", mapSentimentValue(val));
      }
      if (key === "post_date" && formTrend.getFieldValue(["post_date"])) {
        formTrend.setFieldValue(
          "post_date",
          val ? dayjs(val) : dayjs("01-02-2024 00:00"),
        );
      }
    }
    if (state.image_path && state?.image_path?.length > 0) {
      setImageType("upload");
      formTrend.setFieldValue("imageImport", "upload");
      formTrend.setFieldValue("image_path", state?.image_path);
      setPreviews(state?.image_path);
      setImages(state?.image_path);
    } else if (state?.image_url && state?.image_url?.length > 0) {
      setImageType("import");
      formTrend.setFieldValue("imageImport", "import");
      formTrend.setFieldValue("imageURL", state?.image_url[0]);
    } else {
      setImageType("noImage");
      formTrend.setFieldValue("imageImport", "noImage");
      formTrend.setFieldValue("imageURL", null);
      formTrend.setFieldValue("image_path", []);
    }
  };

  const getData = async () => {
    const res = await keywordProviders.getListKeyword();
    if (res) {
      const tempArr = res.reduce((acc, cur) => {
        return [...acc, { value: cur.id, label: cur.name }];
      }, []);

      setMainKeyword(tempArr);
    }
  };

  const SetDefaultValue = () => {
    // formTrend.setFieldValue("sentiment", "natural");
    // formTrend.setFieldValue("negative_trend", 50);
    // formTrend.setFieldValue("positive_trend", 50);
    formTrend.setFieldValue("comments", 0);
    formTrend.setFieldValue("likes", 0);
    formTrend.setFieldValue("shares", 0);
    formTrend.setFieldValue("views", 0);
    formTrend.setFieldValue("campaign_id", 1);
    formTrend.setFieldValue("arrow", "in");
    formTrend.setFieldValue("imageImport", "noImage");
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (state?.type === "edit") {
      setEditValue();
      setIsInsert(false);
    } else {
      SetDefaultValue();
    }
    if (state === null) {
      navigate("/list-post");
    }
  }, [isInsert]);

  const validateWhitespace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("Please enter letters."));
    }
    return Promise.resolve();
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const handleFormChange = (changedValues, allValues) => {
    if (allValues?.imageImport) {
      setImageType(changedValues.imageImport);
    }
  };

  const validateImage = (_, value) => {
    const imageImport = formTrend.getFieldValue("imageImport");
    const titleInput = formTrend.getFieldValue("title");
    const imageURL = formTrend.getFieldValue("imageURL");

    if (
      imageImport === "noImage" &&
      (titleInput === undefined || titleInput?.trim().length === 0)
    ) {
      return Promise.reject(new Error("Please enter a valid image URL"));
    }
    if (imageImport === "upload" && images?.length === 0) {
      return Promise.reject(new Error("Please upload an image"));
    }

    if (
      imageImport === "import" &&
      (imageURL === undefined || imageURL?.trim().length === 0)
    ) {
      return Promise.reject(new Error("Please enter a valid image URL"));
    }

    // return Promise.reject(new Error("Please enter a Title"));
    return Promise.resolve();
  };

  const validateTitle = (_, value) => {
    const imageImport = formTrend.getFieldValue("imageImport");
    const titleInput = formTrend.getFieldValue("title");
    const imageURL = formTrend.getFieldValue("imageURL");
    if (
      imageImport === "noImage" &&
      (titleInput === undefined || titleInput?.trim().length === 0)
    ) {
      return Promise.reject(new Error("Please enter a Title"));
    }
    if (
      imageImport === "upload" &&
      (titleInput === undefined || titleInput?.trim().length === 0) &&
      previews.length === 0
    ) {
      return Promise.reject(new Error("Please enter a Title"));
    }

    if (
      imageImport === "import" &&
      (titleInput === undefined || titleInput?.trim().length === 0) &&
      (imageURL?.trim().length === 0 || imageURL === undefined)
    ) {
      return Promise.reject(new Error("Please enter a Title"));
    }
    return Promise.resolve();
  };

  // const validateImageArray = (rule, value) => {
  //   if (!Array.isArray(value)) {
  //     return Promise.reject("The input should be an array of URLs");
  //   }

  //   const urlPattern = new RegExp("^(https?|ftp)://[^s/$.?#].[^s]*$", "i");

  //   for (let i = 0; i < value.length; i++) {
  //     if (!urlPattern.test(value[i])) {
  //       return Promise.reject(`Invalid URL format at index ${i}`);
  //     }
  //   }

  //   return Promise.resolve();
  // };

  return (
    <MainLayout>
      <img
        src="http://139.59.83.161:9003/image/file_1721039587102.jpg'"
        alt=""
      />
      <StyleInputForm>
        <div id="input-form">
          <h2 className="input-form-header">
            {isInsert ? "ADD Post" : "Edit Post"}
          </h2>
          <Form
            form={formTrend}
            labelCol={{
              span: 3,
            }}
            layout="horizontal"
            style={{
              width: "100%",
              maxWidth: "1000px",
              margin: "auto",
              padding: "16px",
            }}
            onFinish={handleSubmit}
            autoComplete="off"
            onValuesChange={handleFormChange}
            // onValuesChange={onFormChange}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  // required: true,
                  // message: "Please enter a b title",
                },
                { validator: validateTitle },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a a title" />
            </Form.Item>
            <Form.Item label="Poster" name="poster">
              <ThemeStyledInput placeholder="Please enter a poster" />
            </Form.Item>
            <FormItem
              label="Import Image"
              name="imageImport"
              rules={[{ required: true, message: "Please select Image" }]}
            >
              <Radio.Group>
                {importImageOption.map((option) => (
                  <StyledRadio value={option.value}>
                    <Flex align="center">
                      {option.label && <span>{option.label}</span>}
                    </Flex>
                  </StyledRadio>
                ))}
              </Radio.Group>
            </FormItem>
            <div style={{ display: "flex", marginBottom: "24px" }}>
              <TitleBlockImage />
              {formTrend.getFieldValue("imageImport") === "upload" && (
                <Form.Item
                  label=""
                  name="imageUpload"
                  style={{ marginBottom: "0px !important" }}
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please enter a imageURL",
                    // },
                    { validator: validateImage },
                  ]}
                >
                  <ImageUpload
                    onUploadComplete={(res) => onUploadComplete(res)}
                    images={images}
                    previews={previews}
                    progress={progress}
                    error={error}
                    fileInputRef={fileInputRef}
                    handleFileChange={handleFileChange}
                    handleRemoveImage={handleRemoveImage}
                    uploadFile={uploadFile}
                  />
                </Form.Item>
              )}
            </div>

            {formTrend.getFieldValue("imageImport") === "import" && (
              <Form.Item
                label="Link Image URL"
                name="imageURL"
                style={{ marginBottom: "0px !important" }}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please enter a imageURL",
                  // },
                  {
                    type: "url",
                    required: true,
                    message: "invalid format url",
                  },
                  { validator: validateImage },
                ]}
              >
                <ThemeStyledInput placeholder="Please enter a imageURL" />
              </Form.Item>
            )}
            <Form.Item label="Description" name="description">
              <ThemeStyledTextArea
                rows={5}
                placeholder="Please enter a description"
              />
            </Form.Item>
            <Form.Item
              label="Url"
              name="url"
              rules={[
                // { required: true, message: "Please enter a url" },
                {
                  type: "url",
                  required: true,
                },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a url" />
            </Form.Item>
            <Form.Item
              label="Platform"
              name="platform"
              rules={[{ required: true, message: "Please select a platform" }]}
            >
              <ThemeStyledSelect
                placeholder="Select a platform"
                options={options}
              />
            </Form.Item>
            <Form.Item label="Engagement">
              <Flex justify="space-between" gap=" 16px">
                <Form.Item
                  label="Likes"
                  name="likes"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter likes" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Shares"
                  name="shares"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter shares" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Comments"
                  name="comments"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter comments" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Views"
                  name="views"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter views" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
              </Flex>
            </Form.Item>
            {state?.type === "add" ? (
              <FormItem
                label="Sentiment"
                name="sentiment"
                className="sentiment"
              >
                <Radio.Group>
                  {sentimentOptions.map((e) => (
                    <StyledRadio value={e.value}>
                      <SentimentRedio
                        color={e.id === "natural" ? "black" : "white"}
                        backgroundColor={sentimentTypeColor(e.id)}
                      >
                        {e.label}
                      </SentimentRedio>
                    </StyledRadio>
                  ))}
                </Radio.Group>
              </FormItem>
            ) : (
              <Form.Item label="Sentiment" name="sentiment">
                <Button onClick={() => setIsSentimentModalOpen(true)}>
                  Weight Sentiment
                </Button>
              </Form.Item>
            )}

            <FormItem
              label="Arrow"
              name="arrow"
              rules={[{ required: true, message: "Please select a arrow" }]}
            >
              <Radio.Group>
                {arrowOptions.map((option) => (
                  <StyledRadio value={option.value}>
                    <Flex align="center">
                      {option.icon && (
                        <img src={option.icon} alt={`icon-${option.label}`} />
                      )}
                      {option.label && <span>{option.label}</span>}
                    </Flex>
                  </StyledRadio>
                ))}
              </Radio.Group>
            </FormItem>
            <Form.Item
              label="Campaign"
              name="campaign_id"
              rules={[
                { required: true, message: "Please select a campaign_id" },
              ]}
            >
              <RadioGroupCampaign>
                {mainKeyword.map((e) => (
                  <StyledRadio value={e.value}>{e.label}</StyledRadio>
                ))}
              </RadioGroupCampaign>
            </Form.Item>
            <Form.Item
              label="Keyword"
              name="keyword"
              rules={[
                { required: true, message: "Please enter a keyword" },
                { validator: validateWhitespace },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a keyword" />
            </Form.Item>
            <Form.Item label="Post Date" name="post_date">
              <DatePicker
                disabledDate={disabledDate}
                showTime={{
                  defaultValue: moment("00:01", "HH:mm"),
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
            {state?.type === "edit" && (
              <FormItem
                label="Status"
                name="status"
                rules={[{ required: true, message: "Please select a status" }]}
                width="183px"
              >
                <ThemeStyledSelect
                  placeholder="Select filter status"
                  options={optionsFilterStatus}
                  width="183px"
                />
              </FormItem>
            )}
            <Form.Item>
              <Flex justify="center" gap="16px">
                <Button
                  onClick={() => {
                    showModal();
                  }}
                  style={{ width: "180px" }}
                >
                  Cancel
                </Button>
                <ButtonConfirm type="primary" htmlType="submit">
                  Send
                </ButtonConfirm>
              </Flex>
            </Form.Item>
          </Form>
        </div>
      </StyleInputForm>
      <ModalConfirmComponent
        okText="Confirm"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelColor="red"
      >
        <p>Are you sure cancel?</p>
      </ModalConfirmComponent>
      <EditSentiment
        sentimentId={state?.trendId}
        setModalOpen={setIsSentimentModalOpen}
        modalOpen={isSentimentModalOpen}
        options={sentimentOptions}
      />
    </MainLayout>
  );
};

export default InputForm;
