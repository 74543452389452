/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import jsCookie from "js-cookie";
import FilterList from "../features/listTrend/filterList";
import ListTable from "../features/listTrend/listTable";
import MainLayout from "../common/layouts/main";
import {
  StyledTabs,
  StyledButtonAdd,
} from "../common/components/ThemeStyledComponent";
import SelectFilter from "../common/components/SelectFilterComponent";
import { keysIcons } from "../common/utils/keyDatas";
import TitleList from "../features/listTrend/TitleList";
import { setKeyTrendList } from "../features/listTrend/filterListSlice";

const ListTrendBackground = styled(Flex)`
  padding: 4px 16px;
  background: ${(props) => props.theme.backgroundDashBoard};
`;

const BackgroundTab = styled(Flex)`
  height: calc(100dvh - 245px);
  background: ${(props) => props.theme.backgroundDashBoard};
  overflow: auto;
`;

const optionsFilterStatus = [
  {
    value: 0,
    label: "รออนุมัติ",
  },
  {
    value: 1,
    label: "อนุมัติ",
  },
  {
    value: 2,
    label: "สถานะทั้งหมด",
  },
];

const ListTrend = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const campaignIdCookies = jsCookie.get("campaignId");

  const filterListState = useSelector((state) => state.filterList);

  const url = window.location.href;
  const type = url.match(/\/list-(\w+)/)[1];
  const [filterStatus, setFilterStatus] = useState(2);
  const [selectCampaign, setSelectCampaign] = useState(campaignIdCookies);
  const [isModalComment, setIsModalComment] = useState(false);

  const onChageSelectCampaign = (e) => {
    setSelectCampaign(e);
    if (type === "post") navigate(`/list-post/${e}`);
    else navigate(`/list-comment/${e}`);
  };

  useEffect(() => {
    if (
      filterListState.filterStatus !== null &&
      filterListState.filterStatus !== undefined
    ) {
      setFilterStatus(filterListState.filterStatus);
    }
  }, [filterListState.filterStatus]);

  const handleChangeFilteStatus = (value) => {
    const payload = {
      key: "filterStatus",
      value,
    };
    dispatch(setKeyTrendList(payload));
    setFilterStatus(value);
  };

  const onAddTrend = () => {
    navigate("/add/trend", {
      state: {
        type: "add",
        current_campaign_id: selectCampaign,
      },
    });
  };

  const onChangeTabs = (e) => {
    if (e === "post") navigate(`/list-post/${selectCampaign || "campaignId"}`);
    else navigate(`/list-comment/${selectCampaign || "campaignId"}`);
  };

  return (
    <MainLayout>
      <ListTrendBackground id="list-trend" vertical gap={16}>
        <TitleList
          title="ข่าวฮิตติดเทรนด์"
          onChageCampaign={onChageSelectCampaign}
          campaignId={selectCampaign}
        />
        <StyledTabs
          display="none"
          centered
          onChange={(e) => onChangeTabs(e)}
          activeKey={type}
          items={[
            {
              label: "Topic",
              key: "post",
            },
            {
              label: "Reply",
              key: "comment",
            },
          ]}
        />

        <FilterList
          handleChangeFilteStatus={handleChangeFilteStatus}
          optionsFilterStatus={optionsFilterStatus}
          filterStatus={filterStatus}
        >
          {type === "post" ? (
            <StyledButtonAdd
              type="primary"
              color="#0EA67F"
              onClick={() => onAddTrend()}
            >
              <img
                src={keysIcons.add}
                alt="add"
                style={{
                  height: "20px",
                  width: "20px",
                }}
              />
              Add Topic
            </StyledButtonAdd>
          ) : (
            <StyledButtonAdd
              type="primary"
              color="#0EA67F"
              onClick={() => setIsModalComment(true)}
            >
              <img
                src={keysIcons.comment}
                alt="add"
                style={{
                  height: "20px",
                  width: "20px",
                }}
              />
              Comment
            </StyledButtonAdd>
          )}
        </FilterList>
        <BackgroundTab vertical>
          <ListTable
            filterStatus={filterStatus}
            tabType={type}
            isModalComment={isModalComment}
            setSelectCampaign={setSelectCampaign}
            setIsModalComment={setIsModalComment}
          />
        </BackgroundTab>
      </ListTrendBackground>
    </MainLayout>
  );
};

export default ListTrend;
