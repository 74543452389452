import styled from "styled-components";
import { useState } from "react";
import { Flex } from "antd";

import { useDispatch, useSelector } from "react-redux";
import CheckboxComponent from "../../../common/components/CheckboxComponent";
import { setFilterTrendList, setKeyTrendList } from "../filterListSlice";
import SelectComponent from "../../../common/components/SelectComponent";

import IconFaceBook from "../../../assets/icons/icon-facebook.svg";
import IconTwitter from "../../../assets/icons/icon-x.svg";
import IconTikTok from "../../../assets/icons/icon-tiktok.svg";
import IconPantip from "../../../assets/icons/icon-pantip.svg";
import IconInternet from "../../../assets/icons/icon-internet.svg";
import IconYoutube from "../../../assets/icons/icon-youtube.svg";

const StyleFilterSocial = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  /* padding: 8px 16px; */
  /* background: ${(props) => props.theme.backgroundFilter}; */

  .filter-header {
    font-size: 24px;
    font-weight: 700;
    color: ${(props) => props.theme.colorTextButton};
  }

  .filter-checkbox-group img {
    width: 24px;
    height: 24px;
  }
`;

export const stateSelectCardFilter = [
  {
    value: "created_date",
    label: "มาใหม่",
  },
  {
    value: "positive_trend",
    label: "Most Positive",
  },
  {
    value: "negative_trend",
    label: "Most Negative",
  },
];

const FilterSocial = ({
  handleChangeFilteStatus,
  filterStatus,
  optionsFilterStatus,
  children,
}) => {
  const dispatch = useDispatch();
  const { filter, sortby } = useSelector((state) => state.filterList);
  const { facebook, twitter, tiktok, pantip, internet, youtube } = filter;
  // const { Search } = Input;

  const stateCheckPlatform = [
    {
      key: "facebook",
      id: "facebook",
      icon: IconFaceBook,
      label: "",
      checked: facebook,
    },
    {
      key: "twitter",
      id: "twitter",
      icon: IconTwitter,
      label: "",
      checked: twitter,
    },
    {
      key: "tiktok",
      id: "tiktok",
      icon: IconTikTok,
      label: "",
      checked: tiktok,
    },
    {
      key: "pantip",
      id: "pantip",
      icon: IconPantip,
      label: "",
      checked: pantip,
    },
    {
      key: "internet",
      id: "internet",
      icon: IconInternet,
      label: "",
      checked: internet,
    },
    {
      key: "youtube",
      id: "youtube",
      icon: IconYoutube,
      label: "",
      checked: youtube,
    },
  ];

  const [stateCheck, SetStateCheck] = useState(stateCheckPlatform);
  const [stateSelect, setStateSelect] = useState(sortby);

  // const onChageCheckbox = (e, index) => {
  //   // set State Checkbox
  //   const { checked } = e.target;
  //   SetStateCheck((prev) => {
  //     const temp = [...prev];
  //     temp[index] = {
  //       ...temp[index],
  //       checked,
  //     };
  //     return temp;
  //   });

  //   // set reducer
  //   const payload = {
  //     key: stateCheck[index].id,
  //     value: checked,
  //   };
  //   dispatch(setFilterTrendList(payload));
  // };

  const onChageCheckbox = (e, index) => {
    const { checked } = e.target;

    const checkedCount = stateCheck.filter((item) => item.checked).length;

    if (!checked && checkedCount === 1) {
      return;
    }

    SetStateCheck((prev) => {
      const temp = [...prev];
      temp[index] = {
        ...temp[index],
        checked,
      };
      return temp;
    });

    const payload = {
      key: stateCheck[index].id,
      value: checked,
    };
    dispatch(setFilterTrendList(payload));
  };

  const onChageSelect = (e) => {
    if (!e) return;
    setStateSelect(e);
    const payload = {
      key: "sortby",
      value: e,
    };
    dispatch(setKeyTrendList(payload));
  };

  // const onSearch = (value, _e, info) => console.log(info?.source, value);

  return (
    <Flex vertical="vertical">
      <StyleFilterSocial>
        <Flex align="center" gap={16}>
          <span>Status :</span>
          <SelectComponent
            value={filterStatus}
            onChange={handleChangeFilteStatus}
            options={optionsFilterStatus}
            style={{
              width: "120px",
            }}
          />

          <SelectComponent
            value={stateSelect}
            onChange={onChageSelect}
            options={stateSelectCardFilter}
            style={{
              width: "120px",
            }}
          />
        </Flex>
        <Flex className="filter-checkbox-group" align="center" gap="8px">
          <CheckboxComponent
            listCheck={stateCheck}
            onChange={onChageCheckbox}
            gap="20px"
          />
          <div>{children}</div>
        </Flex>
      </StyleFilterSocial>
    </Flex>
  );
};

export default FilterSocial;
