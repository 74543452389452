/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from "react";
import {
 Card, Flex, Row, Col, Empty, Grid
} from "antd";
import styled from "styled-components";
import VersusChart from "../../chartSocial/VersusChart";
import EmptyCard from "./EmptyCard";

const { useBreakpoint } = Grid;

 const EmptyLayout = styled(Flex)`
  height: 434px;
  padding: 10px;
`;

const CardStyle = styled(Card)`
  /* height: 434px; */
  /* width: 100%; */
  padding: 10px;
`;

const VersusTab = ({ data }) => {
  const screens = useBreakpoint();

  return (
    <Flex vertical gap={16}>
      {data?.length !== 0 ? data.map((e, index) =>(
        <Row gutter={[16, 16]}>
          <Col {...(screens.lg ? {
                span: 8 
                } : {
                span: 12 
                })}>
            <Flex gap={16} vertical>
            {e?.data?.overAll?.length ?
              <CardStyle>
                <VersusChart
                title="Over All"
                  data={e?.data?.overAll}
                  typeChart="over-all"
                  dateTime={e?.date}
                />
              </CardStyle>
              :
              <EmptyCard title="Over All" dateTime={e?.date}/>
              }
            </Flex>
          </Col>
          <Col {...(screens.lg ? {
                span: 8 
                } : {
                span: 12 
                })}>
            <Flex gap={16} vertical>
            {e?.data?.topics?.length ?
              <CardStyle>
                 <VersusChart
                 title="Topics"
                  data={e?.data?.topics}
                  typeChart="topics"
                  dateTime={e?.date}
                />
              </CardStyle>
              :
              <EmptyCard title="Topics" dateTime={e?.date}/>
                }
            </Flex>
          </Col>
          <Col {...(screens.lg ? {
                span: 8
                } : {
                span: 12 
                })}>
            <Flex gap={16} vertical>
            {e?.data?.replies?.length ?
              <CardStyle>
                 <VersusChart
                 title="Replies"
                  data={e?.data?.replies}
                  typeChart="replies"
                  dateTime={e?.date}
                />
              </CardStyle>
              :
              <EmptyCard title="Replies" dateTime={e?.date}/>
                }
            </Flex>
          </Col>
        </Row>
      )
      ) : (
        <EmptyLayout justify="center" align="center"><Empty description="Choose Campaign to Compare"/></EmptyLayout>
      )}
    </Flex>
  );
};

export default VersusTab;
