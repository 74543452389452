/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useEffect } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import moment from "moment";
import styled from "styled-components";
import { keysIcons } from "../../../common/utils/keyDatas";
import FilterKeyword from "../filterKeyword";
import { StyledButtonAdd } from "../../../common/components/ThemeStyledComponent";
import KeywordModal from "../keywordModal";

const StyledTableMainKeyword = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

  .table-head-list-keyword,
  .table-body-list-keyword {
    display: flex;
    > tr {
      width: 100%;
      display: grid;
      grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr;
    }
  }

  .table-head-list-keyword {
    text-align: left;
    background-color: ${(props) => props.theme.colortitletable};
    border-radius: 10px 10px 0 0;
    padding: 8px;
    color: white;
  }

  .legend-cicle {
    width: 9px;
    height: 9px;
    border-radius: 200px;
  }

  .table-body-list-keyword {
    flex-direction: column;
    background-color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.backgroundDashBoard};
    border-radius: 0 0 10px 10px;
    height: calc(100dvh - 245px);
    overflow: auto;

    > tr {
      padding: 8px 0;

      &:hover {
        background-color: ${(props) => props.theme.backgroundDashBoard};
      }

      > td {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  background: transparent !important;
  color: ${(props) => props.theme.colorcheckbox} !important;

  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

const ListKeyword = ({ data, openModalConfirmDelete, getData }) => {
  const [filterStatus, setFilterStatus] = useState(2);
  const [modal, setModal] = useState(false);

  const handleChangeFilteStatus = (value) => {
    setFilterStatus(value);
  };

  const optionsFilterStatus = [
    {
      value: 0,
      label: "New",
    },
    {
      value: 2,
      label: "Oldest",
    },
  ];
  return (
    <div
      id="list-keyword-container"
      style={{
        width: "100%",
      }}
    >
      <Flex vertical gap="16px">
        <span
          className="input-keyword-header"
          style={{
            fontSize: "24px",
            fontWeight: 400,
          }}
        >
          Campaigns
        </span>
        <FilterKeyword
          optionsFilterStatus={optionsFilterStatus}
          filterStatus={filterStatus}
          handleChangeFilteStatus={handleChangeFilteStatus}
          counts={data.length}
        >
          <StyledButtonAdd
            type="primary"
            color="#0EA67F"
            onClick={() =>
              setModal({
                isOpen: true,
                data: null,
              })
            }
          >
            <img
              src={keysIcons.add}
              alt="add"
              style={{
                height: "20px",
                width: "20px",
              }}
            />
            New Campaign
          </StyledButtonAdd>
        </FilterKeyword>
        <StyledTableMainKeyword className="table-list-keyword">
          <thead className="table-head-list-keyword">
            <tr>
              <th>No.</th>
              <th>Campaign Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th />
            </tr>
          </thead>
          <tbody className="table-body-list-keyword">
            {data.length === 0 ? (
              <Flex
                justify="center"
                align="center"
                style={{
                  height: "150px",
                }}
                gap="8px"
              >
                <InfoCircleOutlined />
                <span>ไม่พบข้อมูล</span>
              </Flex>
            ) : (
              data.map((item, index) => (
                <tr key={item.id}>
                  <td
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      paddingLeft: "16px",
                    }}
                  >
                    <Flex align="center" gap={8}>
                      <div
                        className="legend-cicle"
                        style={{
                          background: `${item.background_color}`,
                        }}
                      />
                      {item.name}
                    </Flex>
                  </td>
                  <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                  <td>{moment(item.end_date).format("YYYY-MM-DD HH:mm")}</td>
                  <td
                    style={{
                      gap: "8px",
                      justifyContent: "center",
                    }}
                  >
                    <StyledButton
                      type="text"
                      size="small"
                      hoverColor="orange"
                      onClick={() =>
                        setModal({
                          isOpen: true,
                          data: {
                            ...item,
                          },
                          type: "edit",
                        })
                      }
                    >
                      <img
                        src={keysIcons.edit}
                        alt="edit"
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </StyledButton>
                    <StyledButton
                      type="text"
                      size="small"
                      hoverColor="red"
                      onClick={() => openModalConfirmDelete(item.id)}
                    >
                      <img
                        src={keysIcons.delete}
                        alt="delete"
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </StyledButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </StyledTableMainKeyword>
      </Flex>
      <KeywordModal
        isOpen={modal.isOpen}
        data={modal.data}
        type={modal.type}
        getData={getData}
        closeModal={() =>
          setModal({
            isOpen: false,
            data: null,
            type: null,
          })
        }
      />
    </div>
  );
};

export default ListKeyword;
