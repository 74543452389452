import React, { useEffect } from "react";
import { Chart } from "@antv/g2";
import { styled } from "styled-components";
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import { Empty } from "antd";
import { capitalize } from "../../../../common/utils/format";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const LineChart = (props) => {
  const { data, idChart } = props;

  const platformColor = (value) => {
    switch (value) {
      case "twitter":
        return "#1BBBFF";
      case "facebook":
        return "#3D7DE5";
      case "tiktok":
        return "#49474C";
      case "pantip":
        return "#7F36DB";
      case "internet":
        return "#0EA67F";
      case "youtube":
        return "#FF2643";
      default:
        return "#C0C0C0";
    }
  };

  // const titleLeft = (node, y, text, fill) => {
  //   node
  //     .text()
  //     .style("x", -10)
  //     .style("y", y)
  //     .style("text", text)
  //     .style("fontWeight", "bold")
  //     .style("dy", -10)
  //     .style("transform", "rotate(-90)")
  //     .style("fill", fill);
  // };

  useEffect(() => {
    if (data?.length > 0) {
      const chart = new Chart({
        container: `line-chart-${idChart}`,
        paddingTop: 10,
        height: 300,
        // width: 568,
        autoFit: true,
      });

      const axis = {
        zIndex: 1,
        titlePosition: "right",
        line: true,
        labelStroke: "#fff",
        labelStrokeWidth: 5,
        labelFontSize: 10,
        labelStrokeLineJoin: "round",
        labelTransform: "translate(0, 0) rotate(0)",
        lineStrokeWidth: 1,
      };

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      chart
        .line()
        .data(data)
        .encode("x", (d) =>
          new Date(d.created_date).toLocaleDateString("en-US", options),
        )
        .axis({
          x: {
            labelFormatter: (datum) => {
              return moment(datum).format("DD MMM");
            },
            zIndex: 1,
            line: true,
            labelFontSize: 10,
            labelStrokeLineJoin: "round",
            labelTransform: "rotate(-45)",
            lineStrokeWidth: 1,
          },
        })
        .axis("y", axis)
        .encode("y", "treandCount")
        .encode("color", (d) => capitalize(d.platform))
        .scale("color", {
          relations: [
            ["Twitter", "#1BBBFF"],
            ["Facebook", "#3D7DE5"],
            ["Tiktok", "#49474C"],
            ["Pantip", "#7F36DB"],
            ["Internet", "#0EA67F"],
            ["Youtube", "#FF2643"],
          ],
        })
        .scale("color", {
          domain: [
            "Facebook",
            "Twitter",
            "Tiktok",
            "Pantip",
            "Internet",
            "Youtube",
          ],
          range: [
            "#3D7DE5",
            "#1BBBFF",
            "#49474C",
            "#7F36DB",
            "#0EA67F",
            "#FF2643",
          ],
        })
        .encode("y", (d) =>
          d.treand === "positive" ? d.treandCount : -d.treandCount,
        )
        .scale("y", {
          domainMin: -100,
          domainMax: 100,
        })
        .encode("size", 2)
        .axis("y", {
          title: "Negative > Positive",
          line: true,
          labelOpacity: 1,
          labelFill: "#000",
        })
        .tooltip({
          // title: {
          //   channel: "none",
          // },
          items: [
            (d) => ({
              color: platformColor(d.platform),
              value: d.treandCount,
              name: d.platform,
            }),
          ],
        })
        .legend({
          color: {
            position: "bottom",
            itemMarker: "circle",
            layout: {
              justifyContent: "center",
            },
          },
        });

      chart.interaction("legendFilter", false);

      chart
        .lineY()
        .data([0])
        .style("stroke", "black")
        .style("lineWidth", 2)
        .style("lineDash", 7);

      // chart.call(titleLeft, "113%", "Negative", "#e25c3b");
      // chart.call(titleLeft, "-3%", "Positive", "#61cdbb");

      chart.render();
    }
  }, [data]);

  return (
    <div>
      <Title>Media Exposure</Title>
      {data?.length > 0 ? <div id={`line-chart-${idChart}`} /> : <Empty />}
    </div>
  );
};

export default LineChart;
