import { ThemeStyledSelect } from "../ThemeStyledComponent";

const SelectComponent = ({ value, onChange, options, style = {} }) => {
  return (
    <div>
      <ThemeStyledSelect
        value={value}
        onChange={onChange}
        options={options}
        style={style}
      />
    </div>
  );
};

export default SelectComponent;
