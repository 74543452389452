import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  filter: {
    facebook: true,
    twitter: true,
    tiktok: true,
    pantip: true,
    internet: true,
    youtube: true,
  },
  sortby: "created_date",
  filterStatus: 2,
};

export const trendListSlice = createSlice({
  name: "trendList",
  initialState,
  reducers: {
    setIntialTrendList: () => initialState,
    setDataTrendList: (state, action) => {
      state.data = action.value;
    },
    setFilterTrendList: (state, action) => {
      state.filter[action.payload.key] = action.payload.value;
    },
    setKeyTrendList: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setIntialTrendList,
  setDataTrendList,
  setFilterTrendList,
  setKeyTrendList,
  setQueryAll,
} = trendListSlice.actions;

export default trendListSlice.reducer;
