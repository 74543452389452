// eslint-disable-next-line object-curly-newline
import { Button, Card, Switch, Flex, Tooltip, Statistic } from "antd";
import styled from "styled-components";
import {
  formatNumber,
  // calculatedTime,
  formatTime,
} from "../../../../common/utils/format";
import IconPin from "../../../../assets/icons/icon-pin.svg";
import IconUnPin from "../../../../assets/icons/icon-unpin.svg";
import IconWarning from "../../../../assets/icons/icon-warning.svg";
import iconX from "../../../../assets/icons/icon-x.svg";
import iconFaceBook from "../../../../assets/icons/icon-facebook.svg";
import iconPantip from "../../../../assets/icons/icon-pantip.svg";
import iconTikTok from "../../../../assets/icons/icon-tiktok.svg";
import iconYoutube from "../../../../assets/icons/icon-youtube.svg";
import iconInternet from "../../../../assets/icons/icon-internet.svg";
import IconBomb from "../../../../assets/icons/icon-bomb.svg";
import IconBoost from "../../../../assets/icons/icon-boost.svg";
import IconBombCancel from "../../../../assets/icons/icon-bomb-cancel.svg";
import IconBoostCancel from "../../../../assets/icons/icon-boost-cancel.svg";
import IconClock from "../../../../assets/icons/icon-clock.svg";

// import StackBarChart from "./StackBarChart";
import StackBarImpact from "./StackBarImpact";
import CardProvider from "../../provider/cardProvider";

const Stage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
  align-items: center;

  .loader {
    width: fit-content;
    position: absolute;
    font-weight: bold;
    top: -20px;
    /* font-family: monospace; */
    font-family: "Sarabun";
    font-size: 14px;
    color: ${(props) => {
      return props.color === "Bomb"
        ? props.theme.colorButtonBoomb
        : props.theme.colorButtonBoost;
    }};
    clip-path: inset(0 7px 0 0);
    animation: l4 1s steps(4) infinite;
  }
  .loader:before {
    content: "กำลัง ${(props) => props.color} ...";
  }
  @keyframes l4 {
    to {
      clip-path: inset(0 -1ch 0 0);
    }
  }
`;

const StyledCard = styled(Card)`
  /* min-height: ${(props) => props.theme.heightCardSocial}; */
  border-color: ${(props) =>
    props.warned
      ? props.theme.colorBorderCardSocialRed
      : props.theme.colorBorderCardSocial};

  .ant-card-body {
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }

  .right {
    min-width: 140px;
  }

  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }

  .button-group {
    width: 100%;
  }

  @media (max-width: 1250px) {
    .card-top {
      flex-direction: column;
      gap: 16px;
    }
    .card-bottom {
      flex-direction: column;
      gap: 24px;

      .left {
        gap: 0 !important;

        .reach-amount {
          justify-content: space-around;
        }
      }

      .right {
        .button-group {
          justify-content: space-evenly;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  width: 110px;
  height: 44px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid ${(props) => props.theme.colorBorderCardSocial};
  font-size: 16px;
  font-weight: 700;

  .ant-btn-icon {
    margin-inline-end: 0 !important;

    img {
      width: 26px;
      height: 26px;
    }
  }
`;

const StyledButtonBomb = styled(StyledButton)`
  background: ${(props) =>
    props.bombed ? props.theme.colorButtonBoomb : props.theme.white};
  color: ${(props) =>
    props.bombed ? props.theme.white : props.theme.colorTextButton};

  &:not(:disabled):hover {
    border-color: ${(props) =>
      props.bombed ? props.theme.white : props.theme.colorButtonBoomb};
    background: ${(props) =>
      props.bombed
        ? props.theme.colorHoverButtonBomb
        : props.theme.white} !important;
    color: ${(props) =>
      props.bombed
        ? props.theme.white
        : props.theme.colorTextButton} !important;
  }
`;

const StyledButtonBoost = styled(StyledButton)`
  background: ${(props) =>
    props.boosted ? props.theme.colorButtonBoost : props.theme.white};
  color: ${(props) =>
    props.boosted ? props.theme.white : props.theme.colorTextButton};

  &:not(:disabled):hover {
    border-color: ${(props) =>
      props.boosted ? props.theme.white : props.theme.colorButtonBoost};
    background: ${(props) =>
      props.boosted
        ? props.theme.colorHoverButtonBoost
        : props.theme.white} !important;
    color: ${(props) =>
      props.boosted
        ? props.theme.white
        : props.theme.colorTextButton} !important;
  }
`;

const StyleCardTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const StyleCardDescription = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  color: ${(props) => props.theme.colorTextDesc};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

const StyleSwitch = styled(Switch)`
  background: ${(props) =>
    props.warned
      ? props.theme.colorBorderCardSocialRed
      : props.theme.colorToggleDisable} !important;

  &:hover {
    background: ${(props) => props.theme.colorHoverButtonBomb} !important;
  }
`;

const StyleEngagement = styled(Statistic)`
  text-transform: capitalize;
  margin-top: 10px;

  .ant-statistic-title {
    font-size: 14px;
    font-weight: 400;
    color: #6b6b6b;
    margin: 0;
  }

  .ant-statistic-content {
    line-height: 20px;
  }

  .ant-statistic-content-value {
    font-size: 24px;
    font-weight: 700;
    color: #000;
  }

  .ant-statistic-content-value-int {
    font-size: 24px;
    font-weight: 700;
    color: #000;
  }
`;

const ImageContainer = styled.img`
  width: 160px;
  cursor: pointer;
`;

const Cards = (props) => {
  const {
    data,
    fetchData,
    page = "dashboard",
    ButtonComp = () => null,
  } = props;
  const cardProvider = CardProvider();

  const handleToggle = async () => {
    await cardProvider.setWarningSocialCards(data.id);
    fetchData();
    // setDatas((prev) => [
    //   ...prev.map((item) =>
    //     item.id === data.id
    //       ? {
    //           ...item,
    //           warned: res.warned,
    //         }
    //       : item,
    //   ),
    // ]);
  };

  const handleClickButton = async (name) => {
    if (name === "bombed") {
      await cardProvider.setBombSocialCards(data.id);
      fetchData();
      // setDatas((prev) => [
      //   ...prev.map((item) =>
      //     item.id === data.id
      //       ? {
      //           ...item,
      //           [name]: res.bombed,
      //         }
      //       : item,
      //   ),
      // ]);
    } else {
      await cardProvider.setBoostSocialCards(data.id);
      fetchData();
      // setDatas((prev) => [
      //   ...prev.map((item) =>
      //     item.id === data.id
      //       ? {
      //           ...item,
      //           [name]: res.boosted,
      //         }
      //       : item,
      //   ),
      // ]);
    }
  };

  const handlePinCard = async () => {
    await cardProvider.setPinedSocialCards(data.id);
    fetchData();
    // setDatas((prev) => [
    //   ...prev.map((item) =>
    //     item.id === data.id
    //       ? {
    //           ...item,
    //           pinned: res.pinned,
    //         }
    //       : item,
    //   ),
    // ]);
  };

  const checkLogo = (type) => {
    switch (type) {
      case "twitter":
        return iconX;
      case "facebook":
        return iconFaceBook;
      case "pantip":
        return iconPantip;
      case "tiktok":
        return iconTikTok;
      case "internet":
        return iconInternet;
      case "youtube":
        return iconYoutube;
      default:
        return "";
    }
  };

  const checkLabelEngagement = (label) => {
    if (data.platform === "twitter") {
      if (label === "shares") return "reposts";
      if (label === "comments") return "replies";
      if (label === "likes") return "likes";
    } else {
      if (label === "shares") return "shares";
      if (label === "comments") return "comments";
      if (label === "likes") return "likes";
    }
    return label;
  };

  const checkLabelTrend = (negativePercent, positivePercent) => {
    if (positivePercent > negativePercent)
      // return `Positive ${positivePercent}%`;
      return `Positive`;
    if (negativePercent > positivePercent)
      // return `Negative ${negativePercent}%`;
      return `Negative`;
    return `Natural`;
  };

  return (
    <StyledCard id="card" warned={data?.warned}>
      {/* Top Part */}
      <Flex className="card-top" justify="space-between">
        <Flex className="left" vertical gap="8px">
          <Flex align="start" gap="8px">
            <img
              src={checkLogo(data?.platform)}
              alt={`Logo-${data?.platform}`}
            />
            <StyleCardTitle>{data?.title}</StyleCardTitle>
            {page === "dashboard" && (
              <Tooltip title={data?.pinned ? "ยกเลิกการปักหมุด" : "ปักหมุด"}>
                <Button
                  type="text"
                  size="small"
                  onClick={() => handlePinCard(!data?.pinned)}
                  style={{
                    background: "transparent",
                  }}
                >
                  <img
                    src={data?.pinned ? IconPin : IconUnPin}
                    alt={`${data?.pinned ? "pin" : "Unpin"} icon`}
                  />
                </Button>
              </Tooltip>
            )}
          </Flex>
        </Flex>

        <Flex className="right" justify="right" align="start" gap="8px">
          {page === "dashboard" && (
            <>
              {data?.warned ? (
                <Flex align="center" gap="8px">
                  <img src={IconWarning} alt="warning icon" />
                  <span>เฝ้าระวัง</span>
                </Flex>
              ) : (
                ""
              )}
              <StyleSwitch
                checked={data?.warned}
                warned={data?.warned}
                onChange={(e) => handleToggle(e)}
              />
            </>
          )}
          {page !== "dashboard" && <div>{ButtonComp}</div>}
        </Flex>
      </Flex>

      {data?.image_path?.map((p) => (
        <ImageContainer
          src={p}
          alt={p}
          onClick={() => window.open(p, "_blank")}
        />
      ))}
      {data?.image_url?.map((p) => (
        <ImageContainer
          src={p}
          alt={p}
          onClick={() => window.open(p, "_blank")}
        />
      ))}

      {/* Description */}
      <div>
        {page === "dashboard" && data?.description?.length > 0 && (
          <StyleCardDescription>{data?.description}</StyleCardDescription>
        )}
        {page !== "dashboard" && (
          <Flex gap="4px">
            <img src={IconClock} alt="clock icon" />
            <span
              style={{
                color: "#6D6E71",
                fontSize: "14px",
              }}
            >
              โพสเมื่อ {formatTime(data?.updated_date)}
            </span>
          </Flex>
        )}
        <Button
          type="link"
          size="small"
          style={{
            display: "flex",
            padding: 0,
            width: "100%",
          }}
        >
          <a
            href={data?.url}
            target="_blank"
            rel="noreferrer"
            style={{
              width: "100%",
            }}
          >
            <span
              className="card-refUrl"
              style={{
                display: "inline-block",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "left",
              }}
            >
              {data?.url}
            </span>
          </a>
        </Button>
      </div>

      {/* Bottom part */}
      <Flex className="card-bottom" justify="space-between">
        <Flex className="left" vertical>
          {page === "dashboard" && (
            <div
              style={{
                position: "relative",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                Impact :
              </span>
              <span
                style={{
                  fontSize: "10px",
                  fontWeight: 480,
                }}
              >
                {checkLabelTrend(data?.negative_trend, data?.positive_trend)}
              </span>
              {/* <Flex
                justify="space-between"
                style={{
                  fontSize: "10px",
                }}
              >
                <span>อันตราย</span>
                <span>ปลอดภัย</span>
              </Flex>
              <StackBarChart
                positiveValue={data?.positive_trend}
                negativeValue={data?.negative_trend}
              /> */}
              <StackBarImpact
                positiveValue={data?.positive_trend}
                negativeValue={data?.negative_trend}
              />
              <div
                id="stackbar-middle-splitter"
                style={{
                  // width: "50%",
                  height: "100%",
                  borderRight: "1px dashed white",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                }}
              />
            </div>
          )}
          <Flex className="reach-amount" gap="16px">
            {["shares", "likes", "comments", "views"].map(
              (type) =>
                (data?.[type] || data?.[type] === 0) && (
                  <StyleEngagement
                    key={`reach-${type}`}
                    title={checkLabelEngagement(type)}
                    value={formatNumber(data?.[type])}
                  />
                ),
            )}
          </Flex>
        </Flex>

        {false && page === "dashboard" && (
          <Flex className="right" align="flex-end" vertical gap="8px">
            <Flex className="button-group" gap="8px">
              <Flex
                vertical
                style={{
                  position: "relative",
                }}
              >
                {Boolean(data?.warned && data?.bombed) && (
                  <Stage color="Bomb">
                    <div className="loader" />
                  </Stage>
                )}
                <StyledButtonBomb
                  type="primary"
                  icon={
                    <img
                      src={data?.bombed ? IconBombCancel : IconBomb}
                      alt="bomb icon"
                    />
                  }
                  disabled={!data?.warned || data?.boosted}
                  bombed={data?.bombed}
                  onClick={() => handleClickButton("bombed", !data?.bombed)}
                >
                  {data?.bombed ? "Cancel" : "Bomb"}
                </StyledButtonBomb>
              </Flex>
              <Flex
                style={{
                  position: "relative",
                }}
                vertical
              >
                {Boolean(data?.warned && data?.boosted) && (
                  <Stage color="Boost">
                    <div className="loader" />
                  </Stage>
                )}
                <StyledButtonBoost
                  type="primary"
                  icon={
                    <img
                      src={data?.boosted ? IconBoostCancel : IconBoost}
                      alt="boost icon"
                    />
                  }
                  disabled={!data?.warned || data?.bombed}
                  boosted={data?.boosted}
                  onClick={() => handleClickButton("boosted", !data?.boosted)}
                >
                  {data?.boosted ? "Cancel" : "Boost!"}
                </StyledButtonBoost>
              </Flex>
            </Flex>
            {/* <Flex justify="flex-end" gap="4px">
              <img src={IconClock} alt="clock icon" />
              <span
                style={{
                  color: "#6D6E71",
                  fontSize: "14px",
                }}
              >
                {calculatedTime(data?.updated_date)}
              </span>
            </Flex> */}
          </Flex>
        )}
      </Flex>
    </StyledCard>
  );
};

export default Cards;
