/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Flex, Select } from "antd";
import styled from "styled-components";
import moment from "moment";
import { useParams } from "react-router-dom";
import jsCookie from "js-cookie";
import MediaExposureTab from "./MediaExposureTab";
import TrackingTab from "./TrackingTab";
import TopKeywordsTab from "./TopKeywordsTab";
import SentimentTab from "./SentimentTab";
import VersusTab from "./VersusTab";
import RiskTab from "./RiskTab";
import SelectShowColumn from "./ShowColumn";
import CampaignProvider from "./provider/campaignProvider";

import FilterDate from "./VersusTab/FilterDate";
import {
  StyledTabs,
  ThemeStyledSelect,
} from "../../../common/components/ThemeStyledComponent";
import ChartProvider from "../provider/chartProvider";

const { Option } = Select;

const StyleDashBoard = styled(Flex)`
  padding: 4px 16px;
  background: ${(props) => props.theme.backgroundDashBoard};

  .header {
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.colorTextButton};
  }
`;

const SelectVersusStyle = styled(Flex)`
  background-color: #ffffff;
  padding: 16px;
`;

const TabCampaignDashboard = () => {
  const { type } = useParams();
  const campaignProvider = CampaignProvider();
  const chartProvider = ChartProvider();
  const [versusOption, setVersusOption] = useState([]);
  const [versusCampaignData, setVersusCampaignData] = useState([]);
  const [showColumn, setShowColumn] = useState(12);
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [selectOne, setSelectOne] = useState(null);
  const [selectTwo, setSelectTwo] = useState(null);
  const [dates, setDates] = useState(null);
  const [hideTab, setHideTab] = useState(null);

  const fetchcheckPermission = async () => {
    const checkTab = await chartProvider.checkPermissionTab("tab=risk");
    jsCookie.set("checkTab", checkTab);
    setHideTab(checkTab);
  };

  const fetchVersusOption = async () => {
    try {
      const versusData = await campaignProvider.getVersusCampaign();
      if (versusData) {
        setVersusOption(versusData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchVersusCampaignData = async (body) => {
    try {
      const versusCampaign = await campaignProvider.versusCampaign(body);

      setVersusCampaignData(versusCampaign);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchVersusOption();
    fetchcheckPermission();
  }, []);

  useEffect(() => {
    const body = {
      start_date: dateStart,
      end_date: dateEnd,
      campaignId_1: selectOne,
      campaignId_2: selectTwo,
    };

    if (
      body.campaignId_1 &&
      body.campaignId_2 &&
      body.start_date &&
      body.end_date
    ) {
      fetchVersusCampaignData(body);
    }
  }, [dateStart, dateEnd, selectOne, selectTwo]);

  const onChageSelectOne = (e) => {
    setSelectOne(e);
  };

  const onChageSelectTwo = (e) => {
    setSelectTwo(e);
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 7;
    return !!tooEarly || !!tooLate;
  };

  const onChangeDate = (e) => {
    if (e) {
      setDateStart(e[0].format());
      setDateEnd(e[1].format());
    }
  };

  const ItemTab = [
    {
      label: "Media Exposure",
      key: "1",
      children: (
        <StyleDashBoard vertical gap={4}>
          <Flex justify="space-between" align="center">
            <span className="header">
              {type === "project" ? "Project" : "Campaign"}
            </span>
            <SelectShowColumn setShowColumn={setShowColumn} />
          </Flex>
          <MediaExposureTab showColumn={showColumn} />
        </StyleDashBoard>
      ),
    },
    {
      label: "Tracking",
      key: "2",
      children: (
        <StyleDashBoard vertical gap={4}>
          <Flex justify="space-between" align="center">
            <span className="header">
              {type === "project" ? "Project" : "Campaign"}
            </span>
            <SelectShowColumn setShowColumn={setShowColumn} />
          </Flex>
          <TrackingTab showColumn={showColumn} />
        </StyleDashBoard>
      ),
    },
    {
      label: "Top Keywords",
      key: "3",
      children: (
        <StyleDashBoard vertical gap={4}>
          <Flex justify="space-between" align="center">
            <span className="header">
              {type === "project" ? "Project" : "Campaign"}
            </span>
            <SelectShowColumn setShowColumn={setShowColumn} />
          </Flex>
          <TopKeywordsTab showColumn={showColumn} />
        </StyleDashBoard>
      ),
    },
    {
      label: "Sentiment",
      key: "4",
      children: (
        <StyleDashBoard vertical gap={4}>
          <Flex justify="space-between" align="center">
            <span className="header">
              {type === "project" ? "Project" : "Campaign"}
            </span>
            <SelectShowColumn setShowColumn={setShowColumn} />
          </Flex>
          <SentimentTab showColumn={showColumn} />
        </StyleDashBoard>
      ),
    },
    {
      label: "Versus",
      key: "5",
      children: (
        <StyleDashBoard vertical gap={4}>
          <Flex vertical gap={16}>
            <FilterDate
              title="Versus Campaign"
              disabledDate={disabledDate}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              onChange={(e) => onChangeDate(e)}
            />
            <SelectVersusStyle align="center" justify="flex-start" gap={16}>
              <ThemeStyledSelect
                value={selectOne}
                placeholder="Please select campaign"
                onChange={(e) => onChageSelectOne(e)}
                style={{
                  width: "216px",
                }}
              >
                {versusOption &&
                  versusOption
                    ?.filter((option) => option?.id !== selectTwo)
                    ?.map((item) => (
                      <Option key={item.id} value={item?.id}>
                        {item.name}
                      </Option>
                    ))}
              </ThemeStyledSelect>
              <span>VS</span>
              <ThemeStyledSelect
                value={selectTwo}
                placeholder="Please select campaign"
                onChange={(e) => onChageSelectTwo(e)}
                style={{
                  width: "216px",
                }}
              >
                {versusOption &&
                  versusOption
                    ?.filter((option) => option?.id !== selectOne)
                    ?.map((item) => (
                      <Option key={item.id} value={item?.id}>
                        {item.name}
                      </Option>
                    ))}
              </ThemeStyledSelect>
            </SelectVersusStyle>
            <VersusTab data={versusCampaignData} />
          </Flex>
        </StyleDashBoard>
      ),
    },
    {
      label: "Risk",
      key: "6",
      closable: false,
      children: (
        <StyleDashBoard vertical gap={4}>
          <Flex justify="space-between" align="center">
            <span className="header">
              {type === "project" ? "Project" : "Campaign"}
            </span>
            <SelectShowColumn setShowColumn={setShowColumn} />
          </Flex>
          <RiskTab showColumn={showColumn} />
        </StyleDashBoard>
      ),
    },
  ];

  return (
    <StyledTabs
      centered
      onChange={() => setShowColumn(12)}
      defaultActiveKey="1"
      items={
        hideTab === "tab allow"
          ? ItemTab
          : ItemTab.filter((item) => item.key !== "6")
      }
    />
  );
};
export default TabCampaignDashboard;
