/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable object-curly-newline */
import { Button, Flex, Form, Radio, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import dayjs from "dayjs";
import {
  setIsOpenModalError,
  setTitleModalError,
} from "../../../common/components/ModalError/modalErrorSlice";
import EditSentiment from "../../sentiment";
import {
  StyleInputForm,
  ThemeStyledTextArea,
  ThemeStyledInputNumber,
  ThemeStyledSelect,
  StyledRadio,
} from "../../../common/components/ThemeStyledComponent";
import ModalConfirmComponent from "../../../common/components/ModalComponent";
import { ThemeStyledInput } from "../../keyword/inputKeyword";
import keywordProvider from "../../keyword/provider/keywordProvider";
import CommentProvider from "../provider/commentProvider";
import {
  options,
  arrowOptions,
  sentimentOptions,
  mapSentimentValue,
  sentimentTypeColor,
  optionsFilterStatus,
} from "../../sentiment/optionsData";

const ButtonConfirm = styled(Button)`
  background-color: #7d5de8;
  width: 180px;
  /* &:hover {
    background:  !important;
  } */
`;

const SentimentRedio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 98px;
  height: 48px;
  border-radius: 10px;
  padding: 0px 16px;
  font-weight: 700;
`;

const RadioGroupCampaign = styled(Radio.Group)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
`;

const FormItem = styled(Form.Item)`
  > div {
    align-items: center;
  }
  .ant-form-item-control-input {
    width: ${(props) => (props?.width ? props?.width : "100%")};
  }
`;

const InputForm = (props) => {
  const dispatch = useDispatch();
  const commentProvider = CommentProvider();
  const keywordProviders = keywordProvider();
  const navigate = useNavigate();
  const [formTrend] = Form.useForm();
  const [isInsert, setIsInsert] = useState(true);
  const [mainKeyword, setMainKeyword] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSentimentModalOpen, setIsSentimentModalOpen] = useState(false);
  const { data, state, setIsModalComment, fetchCommentData, tabType } = props;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    if (state?.type === "add") {
      setIsModalComment(false);
    } else {
      const redirectUrl = state?.page
        ? `/list-comment/${state?.current_campaign_id}/${state?.page}`
        : `/list-comment/${state?.current_campaign_id}`;
      navigate(redirectUrl);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const SetDefaultValue = () => {
    formTrend.setFieldValue("sentiment", "natural");
    // formTrend.setFieldValue("negative_trend", 50);
    // formTrend.setFieldValue("positive_trend", 50);
    formTrend.setFieldValue("comments", 0);
    formTrend.setFieldValue("likes", 0);
    formTrend.setFieldValue("shares", 0);
    formTrend.setFieldValue("views", 0);
    formTrend.setFieldValue("campaign_id", 1);
    formTrend.setFieldValue("arrow", "in");
    formTrend.setFieldValue("title", "");
    formTrend.setFieldValue("description", "");
    formTrend.setFieldValue("url", "");
    formTrend.setFieldValue("platform", "");
    formTrend.setFieldValue("keyword", "");
  };

  const handleSubmit = async (val) => {
    try {
      const body = {
        title: val.title,
        poster: val.poster,
        description: val.description ? val.description : "",
        url: val.url,
        platform: val.platform,
        likes: val.likes,
        shares: val.shares,
        views: val.views,
        comments: val.comments,
        campaign_id: val.campaign_id,
        keyword: val.keyword,
        arrow: val.arrow,
        approved: val?.status,
      };
      const mappedSentimentData = mapSentimentValue(val?.sentiment);

      if (state?.type === "add") {
        body.positive_trend = mappedSentimentData.positive_trend;
        body.negative_trend = mappedSentimentData.negative_trend;
        body.news_id = data.id;
      }

      if (val?.post_date) {
        body.post_date = dayjs(val?.post_date).format("YYYY-MM-DD HH:mm:ss");
      }

      if (state?.type === "add") {
        await commentProvider.addComment(body);
        setIsModalComment(false);
        SetDefaultValue();
        if (tabType !== "post") {
          fetchCommentData();
        }
        // navigate("/list-comment");
      } else {
        await commentProvider.editComment(state?.trendId, body);

        const redirectUrl = state?.page
          ? `/list-comment/${state?.current_campaign_id}/${state?.page}`
          : `/list-comment/${state?.current_campaign_id}`;
        navigate(redirectUrl);
      }
    } catch (error) {
      dispatch(setTitleModalError({ value: "เกิดข้อผิดพลาด" }));
      dispatch(setIsOpenModalError({ value: true }));
    }
  };

  const setEditValue = () => {
    for (const [key, val] of Object.entries(state)) {
      formTrend.setFieldValue(key, val);
      if (key === "sentiment") {
        formTrend.setFieldValue("sentiment", mapSentimentValue(val));
      }
      if (key === "post_date" && formTrend.getFieldValue(["post_date"])) {
        formTrend.setFieldValue(
          "post_date",
          val ? dayjs(val) : dayjs("01-02-2024 00:00"),
        );
      }
    }
  };

  const getData = async () => {
    const res = await keywordProviders.getListKeyword();
    if (res) {
      const tempArr = res.reduce((acc, cur) => {
        return [...acc, { value: cur.id, label: cur.name }];
      }, []);

      setMainKeyword(tempArr);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (state?.type === "edit") {
      setEditValue();
      setIsInsert(false);
    } else {
      SetDefaultValue();
    }
  }, [isInsert]);

  const validateWhitespace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("Please enter letters."));
    }
    return Promise.resolve();
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <div>
      <StyleInputForm backgroundColorEdit={isInsert ? "none" : "show"}>
        <div id="input-form">
          <h2 className="input-form-header">
            {isInsert ? "ADD Comment" : "Edit Comment"}
          </h2>
          <Form
            form={formTrend}
            labelCol={{
              span: 3,
            }}
            layout="horizontal"
            style={{
              width: "100%",
              maxWidth: "1000px",
              margin: "auto",
              padding: "16px",
            }}
            onFinish={handleSubmit}
            autoComplete="off"
            // onValuesChange={onFormChange}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter a title",
                },
                { validator: validateWhitespace },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a title" />
            </Form.Item>
            <Form.Item label="Poster" name="poster">
              <ThemeStyledInput placeholder="Please enter a poster" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <ThemeStyledTextArea
                rows={5}
                placeholder="Please enter a description"
              />
            </Form.Item>
            <Form.Item
              label="Comment URL"
              name="url"
              rules={[
                { required: true, message: "Please enter a url" },
                {
                  type: "url",
                  required: true,
                },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a url" />
            </Form.Item>
            <Form.Item
              label="Platform"
              name="platform"
              rules={[{ required: true, message: "Please select a platform" }]}
            >
              <ThemeStyledSelect
                placeholder="Select a platform"
                options={options}
              />
            </Form.Item>
            <Form.Item label="Engagement">
              <Flex justify="space-between" gap=" 16px">
                <Form.Item
                  label="Likes"
                  name="likes"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter likes" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Shares"
                  name="shares"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter shares" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Comments"
                  name="comments"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter comments" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Views"
                  name="views"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter views" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
              </Flex>
            </Form.Item>
            {state?.type === "add" ? (
              <FormItem
                label="Sentiment"
                name="sentiment"
                className="sentiment"
                rules={[
                  { required: true, message: "Please select a sentiment" },
                ]}
              >
                <Radio.Group>
                  {sentimentOptions.map((e) => (
                    <StyledRadio value={e.value}>
                      <SentimentRedio
                        color={e.id === "natural" ? "black" : "white"}
                        backgroundColor={sentimentTypeColor(e.id)}
                      >
                        {e.label}
                      </SentimentRedio>
                    </StyledRadio>
                  ))}
                </Radio.Group>
              </FormItem>
            ) : (
              <Form.Item label="Sentiment" name="sentiment">
                <Button onClick={() => setIsSentimentModalOpen(true)}>
                  Weight Sentiment
                </Button>
              </Form.Item>
            )}

            <FormItem
              label="Arrow"
              name="arrow"
              rules={[{ required: true, message: "Please select a arrow" }]}
            >
              <Radio.Group>
                {arrowOptions.map((option) => (
                  <StyledRadio value={option.value}>
                    <Flex align="center">
                      {option.icon && (
                        <img src={option.icon} alt={`icon-${option.label}`} />
                      )}
                      {option.label && <span>{option.label}</span>}
                    </Flex>
                  </StyledRadio>
                ))}
              </Radio.Group>
            </FormItem>
            <Form.Item
              label="Campaign"
              name="campaign_id"
              rules={[
                { required: true, message: "Please select a campaign_id" },
              ]}
            >
              <RadioGroupCampaign>
                {mainKeyword.map((e) => (
                  <StyledRadio value={e.value}>{e.label}</StyledRadio>
                ))}
              </RadioGroupCampaign>
            </Form.Item>
            <Form.Item
              label="Keyword"
              name="keyword"
              rules={[
                { required: true, message: "Please enter a keyword" },
                { validator: validateWhitespace },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a keyword" />
            </Form.Item>
            <Form.Item label="Post Date" name="post_date">
              <DatePicker
                disabledDate={disabledDate}
                showTime={{
                  defaultValue: moment("00:01", "HH:mm"),
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
            {state?.type === "edit" && (
              <FormItem
                label="Status"
                name="status"
                rules={[{ required: true, message: "Please select a status" }]}
                width="183px"
              >
                <ThemeStyledSelect
                  placeholder="Select filter status"
                  options={optionsFilterStatus}
                  width="183px"
                />
              </FormItem>
            )}
            <Form.Item>
              <Flex justify="center" gap="16px">
                <Button
                  onClick={() => {
                    showModal();
                  }}
                  style={{ width: "180px" }}
                >
                  Cancel
                </Button>
                <ButtonConfirm type="primary" htmlType="submit">
                  Send
                </ButtonConfirm>
              </Flex>
            </Form.Item>
          </Form>
        </div>
      </StyleInputForm>
      <ModalConfirmComponent
        okText="Confirm"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelColor="red"
        closable={false}
      >
        <p>Are you sure cancel?</p>
      </ModalConfirmComponent>
      <EditSentiment
        sentimentId={state?.trendId}
        newsId={state?.news_id}
        setModalOpen={setIsSentimentModalOpen}
        modalOpen={isSentimentModalOpen}
        options={sentimentOptions}
        type="comment"
      />
    </div>
  );
};

export default InputForm;
