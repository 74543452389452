/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import CardFilter from "./CardFilter";
import Cards from "./Cards";
import CardProvider from "../provider/cardProvider";
import { setKeySocialCard } from "./socialCardSlice";

const StyleSocialComponent = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background: ${(props) => props.theme.white};
  border-radius: 10px;
`;

const StyledButton = styled(Button)`
  background: transparent !important;
  color: ${(props) => props.theme.colorcheckbox} !important;

  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

const CardSocial = () => {
  const cardProvider = CardProvider();
  const dispatch = useDispatch();
  const { filter, sortby, warned, pinned, titleName } = useSelector(
    (state) => state.socialCard,
  );
  const [datas, setDatas] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [counts, setCounts] = useState(25);
  const show = 25;

  const fetchData = async () => {
    try {
      const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

      const platformString = filterPlatform
        .map((platform) => `platform=${platform}`)
        .join("&");

      let params = `?&${platformString}&sort=${sortby}&approved=1`;
      if (warned) params += `&show=warned`;
      if (pinned) params += `&show=pinned`;

      const res = await cardProvider.getSocialCards(params);

      if (!titleName) {
        const payload = {
          key: "titleName",
          value: res?.name,
        };
        dispatch(setKeySocialCard(payload));
      }

      if (res?.data) {
        const data = res?.data?.filter((e) => !!e);
        setDatas(data);
        if (counts >= data.length) {
          setShowAll(true);
        } else {
          setShowAll(false);
        }
        // if (showAll) {
        //   setDataShow(data);
        // } else {
        const currentData = data.slice(0, counts);
        setDataShow(currentData);
        // }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, sortby, warned, pinned, counts]);

  const handleShowMore = () => {
    if (counts <= datas.length) {
      setCounts(counts + show);
    } else {
      setCounts(show);
    }
  };
  return (
    <StyleSocialComponent id="social-card-container" vertical>
      <CardFilter />
      <Flex id="card-container" vertical gap="16px">
        {dataShow.length > 0 &&
          dataShow?.map((data) => (
            <Cards
              key={`card-${data.id}`}
              data={data}
              setDatas={setDatas}
              fetchData={fetchData}
            />
          ))}
      </Flex>
      {datas.length > show && (
        <StyledButton onClick={() => handleShowMore()}>
          {`See ${showAll ? "Less" : "More"}`}{" "}
          {showAll ? <UpOutlined /> : <DownOutlined />}
        </StyledButton>
      )}
    </StyleSocialComponent>
  );
};

export default CardSocial;
