/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from "react";
import { Radio, Flex, Modal, List } from "antd";
import styled from "styled-components";
import { mapSentimentValue, sentimentTypeColor } from "./optionsData";
import SentimentProvider from "./provider/sentimentProvider";
import { calculatedTime } from "../../common/utils/format";
import ProgressBar from "./progressBar";

const StayleSentiment = styled(Flex)`
  width: calc(100% - 30px);
`;

const StyleFlex = styled(Flex)`
  height: 400px;
  .ant-list {
    overflow: auto;
  }
`;

const SentimentRedio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 98px;
  height: 48px;
  border-radius: 10px;
  padding: 0px 16px;
  font-weight: 700;
  text-align: center;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    /* height: 60px; */
    display: flex;
    align-items: center;
  }
  .ant-modal-close-x {
    font-size: 30px;
  }
  .ant-modal-close {
    top: 25px;
    inset-inline-end: 30px;
  }

  .ant-modal-footer {
    padding: 16px;
    text-align: center;
    .ant-btn {
      width: 180px;
    }
    :nth-child(2) {
      background: #7d5de8;
      text-align: center;
    }
  }
  .ant-radio-wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 11px;

    span.ant-radio + * {
      text-align: center;
    }
  }
  .sentiment-type {
    display: flex;
    justify-content: center;
    /* text-align: center; */
    width: calc(100% - 550px);
  }
  .positive-trend {
    width: 200px;

    .ant-progress .ant-progress-bg {
      background-color: green;
    }
  }
  .negative-trend {
    width: 200px;

    .ant-progress .ant-progress-bg {
      background-color: red;
    }
  }
`;

const EditSentiment = ({
  sentimentId,
  newsId,
  modalOpen,
  options,
  setModalOpen,
  type,
}) => {
  const sentimentProvider = SentimentProvider();
  const [value, setValue] = useState(mapSentimentValue("natural"));
  const [listSentiment, setListSentiment] = useState([]);

  const fetchListSentimentData = async () => {
    let sentimentData;
    if (type === "comment") {
      sentimentData = await sentimentProvider.getReplySentiment(sentimentId);
    } else {
      sentimentData = await sentimentProvider.getSentiment(sentimentId);
    }
    setListSentiment(sentimentData);
  };

  useEffect(() => {
    fetchListSentimentData();
  }, [modalOpen]);

  const handleConfirm = async () => {
    try {
      if (type === "comment") {
        await sentimentProvider.updateReplySentiment(sentimentId, {
          ...value,
          news_id: newsId,
        });
      } else {
        await sentimentProvider.updateSentiment(sentimentId, value);
      }
      setModalOpen(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleCancel = () => {
    setModalOpen(false);
  };

  const onChangeSentiment = (e) => {
    setValue(mapSentimentValue(e.target.value));
  };

  const sentimentType = (positiveVal, negativeVal) => {
    if (positiveVal === 0 && negativeVal === 100) {
      return { text: "Strong Negative", textColor: "#FF2643" };
    }
    if (positiveVal === 25 && negativeVal === 75) {
      return { text: "Weak Negative", textColor: "#FE881A" };
    }
    if (positiveVal === 50 && negativeVal === 50) {
      return { text: "Natural", textColor: "#C0C0C0" };
    }
    if (positiveVal === 75 && negativeVal === 25) {
      return { text: "Weak Positive", textColor: "#CADB12" };
    }
    if (positiveVal === 100 && negativeVal === 0) {
      return { text: "Strong Positive", textColor: "#0EA67F" };
    }
    return { text: "Natural", textColor: "#C0C0C0" };
  };

  return (
    <StyledModal
      id="sentiment"
      title="Weight Sentiment"
      okText="Confirm"
      open={modalOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      width={980}
    >
      <StayleSentiment vertical>
        <Flex vertical style={{ padding: "32px 0px" }}>
          <Radio.Group
            onChange={onChangeSentiment}
            value={mapSentimentValue(value)}
          >
            {options.map((e) => (
              <Radio key={e.id} value={e.value}>
                <SentimentRedio
                  color={e.id === "natural" ? "black" : "white"}
                  backgroundColor={sentimentTypeColor(e.id)}
                >
                  {e.label}
                </SentimentRedio>
              </Radio>
            ))}
          </Radio.Group>
        </Flex>

        {listSentiment.length !== 0 && (
          <StyleFlex vertical gap={16}>
            <h3>Sentiments</h3>
            <List
              dataSource={listSentiment}
              renderItem={(item) => (
                <div
                  style={{
                    overflow: "auto",
                    padding: "0 16px",
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                    marginBottom: "8px",
                  }}
                >
                  <List.Item key={item.id} style={{ gap: "16px" }}>
                    <List.Item.Meta
                      title={item.username}
                      description={calculatedTime(item.created_date)}
                    />
                    <div className="sentiment-type">
                      <SentimentRedio
                        backgroundColor={
                          sentimentType(
                            item.positive_trend,
                            item.negative_trend,
                          ).textColor
                        }
                        color={
                          sentimentType(
                            item.positive_trend,
                            item.negative_trend,
                          ).text === "Natural"
                            ? "black"
                            : "white"
                        }
                      >
                        {
                          sentimentType(
                            item.positive_trend,
                            item.negative_trend,
                          ).text
                        }
                      </SentimentRedio>
                    </div>
                    <div className="positive-trend">
                      positive:
                      <ProgressBar
                        progress={item.positive_trend}
                        type="positive"
                      />
                    </div>
                    <div className="negative-trend">
                      negative:
                      <ProgressBar
                        progress={item.negative_trend}
                        type="negative"
                      />
                    </div>
                  </List.Item>
                </div>
              )}
            />
          </StyleFlex>
        )}
      </StayleSentiment>
    </StyledModal>
  );
};

export default EditSentiment;
