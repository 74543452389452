/* eslint-disable no-unused-vars */
import { Flex } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../../../common/components/SelectComponent";
import CheckboxComponent from "../../../../common/components/CheckboxComponent";
import { setQueryAll, setKeySocialCard } from "../socialCardSlice";

import IconPined from "../../../../assets/icons/icon-pin.svg";
import IconWarning from "../../../../assets/icons/icon-warning.svg";

const CardFilter = () => {
  const dispatch = useDispatch();
  const { sortby, isAllData, pinned, warned } = useSelector(
    (state) => state.socialCard,
  );

  const stateCheck = [
    {
      key: "ข้อมูลทั้งหมด",
      type: "isAllData",
      label: "ข้อมูลทั้งหมด",
      checked: isAllData,
    },
    {
      key: "ปักหมุด",
      type: "pinned",
      label: "ปักหมุด",
      checked: pinned,
      icon: IconPined,
    },
    {
      key: "เฝ้าระวัง",
      type: "warned",
      label: "เฝ้าระวัง",
      checked: warned,
      icon: IconWarning,
    },
  ];

  const stateSelectCardFilter = [
    {
      value: "created_date",
      label: "มาใหม่",
    },
    {
      value: "positive_trend",
      label: "Most Positive",
    },
    {
      value: "negative_trend",
      label: "Most Negative",
    },
    // {
    //   value: "pinned",
    //   label: "ปักหมุดขึ้นก่อน",
    // },
    // {
    //   value: "warning",
    //   label: "แจ้งเตือนขึ้นก่อน",
    // },
  ];

  const [stateSelect, setStateSelect] = useState(sortby);

  const onChageCheckbox = (e, index) => {
    const { checked } = e.target;
    // ALL
    if (index === 0) {
      const payload = {
        value: checked,
      };
      dispatch(setQueryAll(payload));
    }
    // CUSTOM
    else {
      const payload = {
        key: stateCheck[index].type,
        value: checked,
      };
      dispatch(setKeySocialCard(payload));
    }
  };

  const onChageSelect = (e) => {
    if (!e) return;
    setStateSelect(e);
    const payload = {
      key: "sortby",
      value: e,
    };
    dispatch(setKeySocialCard(payload));
  };

  useEffect(() => {
    if (pinned && warned) {
      dispatch(
        setQueryAll({
          value: true,
        }),
      );
    } else {
      const payload = {
        key: "isAllData",
        value: false,
      };
      dispatch(setKeySocialCard(payload));
    }
  }, [pinned, warned]);

  return (
    <Flex id="card-filter" vertical>
      <Flex justify="space-between" align="center">
        <span
          style={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Sentiment
        </span>
        <Flex align="center" gap="10px">
          <span>เรียงจาก</span>
          <SelectComponent
            value={stateSelect}
            onChange={onChageSelect}
            options={stateSelectCardFilter}
            style={{
              width: "140px",
            }}
          />
        </Flex>
      </Flex>
      <CheckboxComponent
        listCheck={stateCheck}
        onChange={onChageCheckbox}
        gap="17px"
      />
    </Flex>
  );
};

export default CardFilter;
