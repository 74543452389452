/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Card, Flex, Row, Col } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import LineChart from "../../chartSocial/LineChart";
import TitleCampaign from "../TitleCampaign";
import CampaignProvider from "../provider/campaignProvider";
import LinkFooterCard from "../../LinkFooterCard";

const MediaExposureTab = ({ showColumn }) => {
  const { type } = useParams();
  const campaignProvider = CampaignProvider();
  const [data, setData] = useState([]);

  const fetchLineChartData = async () => {
    try {
      const lineChartData = await campaignProvider.getAllCampaign("line");
      setData(lineChartData);
      // if (type === "campaign") {
      //   const lineChartData = await campaignProvider.getAllCampaign("line");
      //   setData(lineChartData);
      // } else {
      //   const lineChartData = await campaignProvider.getAllProject("line");
      //   setData(lineChartData);
      // }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchLineChartData();
  }, [showColumn, type]);

  return (
    <Row gutter={[16, 16]}>
      {data &&
        data.map((e, index) => (
          <Col span={showColumn} key={index}>
            <Card>
              <Flex gap={16} vertical>
                <TitleCampaign
                  showColumn={showColumn}
                  title={e.name}
                  date={moment(e.start_date).format("DD MMM YYYY")}
                  campaignId={e.id}
                  type={type}
                />
                <LineChart data={e.data} idChart={index} />
              </Flex>
              <LinkFooterCard title={e.name} campaignId={e.id} />
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export default MediaExposureTab;
