import { ThemeProvider } from "styled-components";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import Cookies from "js-cookie";
import theme from "../themes/theme";
import store from "./store";

import { ProvideAuth, useAuth } from "../common/hooks/useProviderAuth";
import { AxiosProvider } from "../common/hooks/useProviderAxios";
import InsertTrends from "../common/components/InsertTrend";
import ModalError from "../common/components/ModalError";

import MainKeyword from "../pages/mainKeyword";
import InputForm from "../pages/inputForm";
import DashBoard from "../pages/dashBoard";
import Login from "../pages/loginpage";
import ListTrend from "../pages/listTrend";
import NewsArchive from "../pages/newsArchive";
import UpdateTrendLatest from "../pages/updateTrendLatest";
import CampaignsDashboard from "../pages/campaignDashboard";
import CommentForm from "../pages/commentForm";

import Loading from "../common/components/LoadingComponent";

const redirect = Cookies.get("redirect");

const PrivateRoute = ({ children }) => {
  const { accessToken } = useAuth();

  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.colorMagenta,
        },
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <ProvideAuth>
            <AxiosProvider>
              <ThemeProvider theme={theme}>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to={redirect} replace />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/dashboard"
                    element={
                      <PrivateRoute>
                        <DashBoard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/:campaignId?/:name?"
                    element={
                      <PrivateRoute>
                        <DashBoard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/:type-dashboard/:campaignId?"
                    element={
                      <PrivateRoute>
                        <CampaignsDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/list-post" element={<ListTrend />} />
                  <Route path="/list-comment" element={<ListTrend />} />
                  <Route
                    path="/list-post/:campaignId?/:pageUrl?"
                    element={<ListTrend />}
                  />
                  <Route
                    path="/list-comment/:campaignId?/:pageUrl?"
                    element={<ListTrend />}
                  />
                  <Route path="/add/trend" element={<InputForm />} />
                  <Route path="/edit/trend" element={<InputForm />} />
                  <Route path="/add/comment" element={<CommentForm />} />
                  <Route path="/edit/comment" element={<CommentForm />} />
                  <Route path="/manage-campaigns" element={<MainKeyword />} />
                  <Route path="/mainpage" element={<InsertTrends />} />
                  <Route path="/update-trend" element={<UpdateTrendLatest />} />
                  <Route path="/news-archive" element={<NewsArchive />} />
                </Routes>
                <ModalError />
                <Loading />
              </ThemeProvider>
            </AxiosProvider>
          </ProvideAuth>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
