/* eslint-disable import/prefer-default-export */
import IconFaceBook from "../../assets/icons/icon-facebook.svg";
import IconTwitter from "../../assets/icons/icon-x.svg";
import IconTikTok from "../../assets/icons/icon-tiktok.svg";
import IconPantip from "../../assets/icons/icon-pantip.svg";
import IconInternet from "../../assets/icons/icon-internet.svg";
import IconYoutube from "../../assets/icons/icon-youtube.svg";
import IconAround from "../../assets/icons/icon-around.svg";
import IconIn from "../../assets/icons/icon-in.svg";
import IconOut from "../../assets/icons/icon-out.svg";
import IconCreate from "../../assets/icons/create-icon.svg";
import IconDelete from "../../assets/icons/delete-list-icon.svg";
import IconAdd from "../../assets/icons/add-list-icon.svg";
import IconComment from "../../assets/icons/icon-comment.svg";

const keysIcons = {
  facebook: IconFaceBook,
  twitter: IconTwitter,
  tiktok: IconTikTok,
  pantip: IconPantip,
  internet: IconInternet,
  youtube: IconYoutube,
  around: IconAround,
  in: IconIn,
  out: IconOut,
  edit: IconCreate,
  delete: IconDelete,
  add: IconAdd,
  comment: IconComment,
};

const stateSelectCardFilter = [
  {
    value: "created_date",
    label: "มาใหม่",
  },
  {
    value: "positive_trend",
    label: "Most Positive",
  },
  {
    value: "negative_trend",
    label: "Most Negative",
  },
  // {
  //   value: "pinned",
  //   label: "ปักหมุดขึ้นก่อน",
  // },
  // {
  //   value: "warning",
  //   label: "แจ้งเตือนขึ้นก่อน",
  // },
];
export { keysIcons, stateSelectCardFilter };
