/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useAxios } from "../../../common/hooks/useProviderAxios";
import {
  mockRiskStackedBarChart,
  mockRiskCellChart,
  testdata,
} from "./mockChart";

const host = process.env.REACT_APP_API_ENDPOINT;

const chartProvider = () => {
  const { axios } = useAxios();

  const getLineChart = async (params) => {
    try {
      const url = `${host}/charts/line${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get line chart", error);
      return [];
    }
  };

  const getBarpercentChart = async (params) => {
    try {
      const url = `${host}/charts/bar${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get bar chart", error);
      return [];
    }
  };

  const getWordCloud = async (params) => {
    try {
      const url = `${host}/charts/wordcloud${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get word cloud", error);
      return [];
    }
  };

  const getBarTotalChart = async (params) => {
    try {
      const url = `${host}/charts/bar-total${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get bar total chart", error);
      return [];
    }
  };

  const getRiskStackedBarChart = async (params) => {
    try {
      // if (mockRiskStackedBarChart) return mockRiskStackedBarChart;
      const url = `${host}/charts/risk${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get stacked bar chart", error);
      return [];
    }
  };

  const getRiskScoreChart = async (params) => {
    try {
      // if (testdata) return testdata;
      const url = `${host}/charts/risk-score${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get risk score chart", error);
      return [];
    }
  };

  const checkPermission = async (params) => {
    try {
      const url = `${host}/users/check-permission?${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on check permission", error);
      return [];
    }
  };

  const checkPermissionTab = async (params) => {
    try {
      const url = `${host}/users/check-tab?${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on check permission tab", error);
      return [];
    }
  };

  return {
    getLineChart,
    getBarpercentChart,
    getWordCloud,
    getBarTotalChart,
    checkPermission,
    getRiskStackedBarChart,
    getRiskScoreChart,
    checkPermissionTab,
  };
};

export default chartProvider;
