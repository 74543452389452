/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import styled from "styled-components";
import useHookProviderUpload from "../../hooks/useProviderUpload";
import listTrendProvider from "../../../features/listTrend/provider/listTrendProvider";
import { ReactComponent as IconGallery } from "../../../assets/icons/icon-gallery.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/icon-close.svg";

const ImageUpload = (props) => {
  const {
    images,
    previews,
    progress,
    error,
    fileInputRef,
    handleFileChange,
    handleRemoveImage,
    uploadFile,
    onUploadComplete,
    setPreviews,
  } = props;

  const apiListTrend = listTrendProvider();

  const HiddenFileInput = styled.input`
    display: none !important;
    /* visibility: hidden; */
  `;

  const UploadButton = styled.div`
    display: flex;
    width: 160px;
    height: 160px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    /* margin: 24px 0; */
    margin-top: 0;
    border-radius: 10px;
    border: 3px dashed #bdb4fd;
    background: #f0eeff;
  `;

  // const TitleBlockImage = styled.div`
  //   width: 12.5%;
  // `;

  const SubTitle2 = styled.div`
    color: ${({ theme }) => theme.colorButtonUpload};
    text-align: center;
    font-family: Sarabun;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
  `;

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const res = await uploadFile();
  //   console.log("res", res);
  //   if (true || res.data) {
  //     onUploadComplete(res);
  //   }
  // };

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <HiddenFileInput
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {/* <TitleBlockImage /> */}
        <div>
          {previews.map((preview, index) => (
            <div
              key={preview}
              style={{
                display: "inline-block",
                position: "relative",
                marginRight: "10px",
              }}
            >
              <img
                src={preview}
                alt="Preview"
                style={{
                  width: "160px",
                  height: "160px",
                  objectFit: "contain",
                  background: "#f0eeff",
                  borderRadius: "10px",
                  border: "3px dashed #bdb4fd",
                }}
              />
              <IconClose
                onClick={() => handleRemoveImage(index)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  background: "black",
                  color: "white",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          ))}
        </div>
        <UploadButton
          onClick={handleUploadButtonClick}
          style={{
            visibility:
              previews.length > 0 || images.length > 0 ? "hidden" : "initial",
          }}
        >
          <IconGallery />
          <SubTitle2>Add Image</SubTitle2>
        </UploadButton>
      </div>
    </div>
  );
};

export default ImageUpload;
