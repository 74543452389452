import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";
import { Card } from "antd";
// import WordCloud from "../../../common/components/TestGrap/WordCloudPlot";
// import BarPrecentChart from "./BarPrecentChart";
import ChartProvider from "../provider/chartProvider";
import RiskStackedBarChart from "../chartSocial/RiskStackedBarChart";
import RiskScoreChart from "../chartSocial/RiskScoreChart";

const StyledChart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RiskManagement = () => {
  const chartProvider = ChartProvider();
  const { filter } = useSelector((state) => state.socialCard);
  const [riskStackedBarChart, setRiskStackedBarChart] = useState([]);
  const [riskScoreChart, setRiskScoreChart] = useState([]);
  const [riskScoreTime, setRiskScoreTime] = useState({});

  const fetchcheckPermission = async () => {
    await chartProvider.checkPermission("page=dashboard");
  };

  const fetchRiskStackedBarChartData = async (params) => {
    const riskStackedBarData =
      await chartProvider.getRiskStackedBarChart(params);
    setRiskStackedBarChart(riskStackedBarData || []);
  };

  const fetchRiskScoreChartData = async (params) => {
    const riskScoreData = await chartProvider.getRiskScoreChart(params);
    setRiskScoreChart(riskScoreData.data || []);
    setRiskScoreTime(riskScoreData.time);
  };

  useEffect(() => {
    const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

    const platformString = filterPlatform
      .map((platform) => `platform=${platform}`)
      .join("&");

    const params = `?&${platformString}`;
    fetchcheckPermission();
    fetchRiskStackedBarChartData(params);
    fetchRiskScoreChartData(params);
  }, [filter]);

  return (
    <StyledChart id="charts">
      <Card>
        <RiskStackedBarChart data={riskStackedBarChart} />
      </Card>
      <Card>
        <RiskScoreChart data={riskScoreChart} time={riskScoreTime} />
      </Card>
    </StyledChart>
  );
};

export default RiskManagement;
