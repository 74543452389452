/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { Flex } from "antd";
import SelectComponent from "../../../common/components/SelectComponent";

const TitleText = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colorTextDesc};
`;

const FilterKeyword = ({
  filterStatus,
  handleChangeFilteStatus,
  optionsFilterStatus,
  counts,
  children,
}) => {
  return (
    <Flex justify="space-between" align="center">
      <Flex align="center" gap={16}>
        <span>Status :</span>
        <SelectComponent
          value={filterStatus}
          onChange={handleChangeFilteStatus}
          options={optionsFilterStatus}
          style={{
            width: "120px",
          }}
        />
        <span>{counts} Items</span>
      </Flex>
      <Flex>{children}</Flex>
    </Flex>
  );
};

export default FilterKeyword;
