/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Chart } from "@antv/g2";
import { styled } from "styled-components";
import { Empty } from "antd";
import moment from "moment";
import { capitalize } from "../../../../common/utils/format";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const RiskScoreChart = (props) => {
  const { data, idChart, time } = props;

  useEffect(() => {
    if (data?.length > 0) {
      const chart = new Chart({
        container: `risk-score-chart-${idChart}`,
        paddingTop: 10,
        height: 400,
        autoFit: true,
      });

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      chart
        .cell()
        .data(data)
        .scale("color", {
          type: "ordinal",
        })
        .encode("y", (d) => capitalize(d.platform))
        // .encode("x", "dateTime")
        .encode("x", (d) =>
          new Date(d.dateTime).toLocaleDateString("en-US", options),
        )
        .encode("color", "levels")
        .axis("x", {
          title: `${moment(time.start).format("DD MMM")} - ${moment(
            time.end,
          ).format("DD MMM")}`,
          labelFormatter: (datum) => {
            return moment(datum).format("DD");
          },
          zIndex: 1,
          line: true,
          labelFontSize: 10,
          labelStrokeLineJoin: "round",
          labelTransform: data.length > 10 ? "rotate(-45)" : "rotate(0)",
          lineStrokeWidth: 1,
          labelOpacity: 1,
          labelFill: "#000",
        })
        .axis("y", {
          title: "Platform",
          labelOpacity: 1,
          labelFill: "#000",
          // labelTextAlign: "start",
          // labelTransform: "translate(-60, 0)",
        })
        .scale("color", {
          relations: [
            ["Negligible", "#62BB46"],
            ["Minor", "#B2D34A"],
            ["Moderate", "#FAD55E"],
            ["High", "#F49B25"],
            ["Critical", "#F15640"],
          ],
        })
        .style("inset", 2)
        // .style("stroke", "#000")
        .legend({
          color: {
            position: "bottom",
            itemMarker: "circle",
            layout: {
              justifyContent: "center",
            },
          },
        });

      chart.render();
    }
  }, [data]);

  return (
    <div>
      <Title>Risk Score</Title>
      {data?.length > 0 ? (
        <div id={`risk-score-chart-${idChart}`} />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default RiskScoreChart;
