/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable object-curly-newline */
import { useLocation } from "react-router-dom";
import MainLayout from "../common/layouts/main";
import Form from "../features/comment/Form";

const CommentForm = () => {
  const location = useLocation();
  const { state = { type: "add" } } = location;

  return (
    <MainLayout>
      <Form state={state} />
    </MainLayout>
  );
};

export default CommentForm;
