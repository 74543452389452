/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Flex } from "antd";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setKeySocialCard } from "../socialCard/socialCardSlice";

const LinkText = styled(Link)`
  font-size: 16px;
  font-weight: 700;
  padding: 11px;
  /* color: ${(props) => props.theme.colorTextDesc}; */
`;

const LinkFooterCard = ({ campaignId, title }) => {
  const { type } = useParams();
  const dispatch = useDispatch();

  const handleClick = () => {
    const payload = {
      key: "titleName",
      value: title,
    };
    dispatch(setKeySocialCard(payload));
  };

  return (
    <Flex gap={16} justify="flex-end">
      {type === "project" && (
        <LinkText
          to={{
            pathname: `/campaign-dashboard/${campaignId}`,
          }}
        >
          Campaign
        </LinkText>
      )}

      <LinkText
        to={{
          pathname: `/dashboard/${campaignId}`,
        }}
        onClick={handleClick}
      >
        Data Visualization
      </LinkText>
    </Flex>
  );
};

export default LinkFooterCard;
