/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Flex, Row, Col, Empty } from "antd";
import { styled } from "styled-components";
import PieChart from "../PieChart";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const ColStyle = styled(Col)`
  margin: -25px -10px;
`;

const Legend = styled(Flex)`
  flex-wrap: wrap;
  .legend-cicle {
    width: 9px;
    height: 9px;
    border-radius: 200px;
  }

  .platform {
    color: #444450;
    font-family: Sarabun;
    font-size: 12px;
    font-style: normal;
  }
`;

const TrackingChart = ({ data, name }) => {
  const platformLegend = [
    {
      platform: "Facebook",
      color: "#3D7DE5",
    },
    {
      platform: "Twitter",
      color: "#1BBBFF",
    },
    {
      platform: "Tiktok",
      color: "#49474C",
    },
    {
      platform: "Pantip",
      color: "#7F36DB",
    },
    {
      platform: "Internet",
      color: "#0EA67F",
    },
    {
      platform: "Youtube",
      color: "#FF2643",
    },
  ];

  return (
    <Flex gap={16} vertical>
      <Title>Social Data</Title>

      {data?.length > 0 ? (
        <>
          <Row
            style={{
              justifyContent: "center",
            }}
          >
            {data &&
              data?.map((e, index) => (
                <ColStyle span={8} key={index}>
                  <PieChart
                    data={e.data}
                    trendName={e.trendName}
                    idChart={index}
                    total={e.total}
                    name={name}
                  />
                </ColStyle>
              ))}
          </Row>
          <Legend justify="center" gap={14} align="center">
            {platformLegend.map((e, index) => (
              <Flex gap={8} align="center">
                <div
                  className="legend-cicle"
                  style={{
                    background: e.color,
                  }}
                />
                <span className="platform">{e.platform}</span>
              </Flex>
            ))}
          </Legend>
        </>
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default TrackingChart;
