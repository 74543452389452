import React from "react";
import styled from "styled-components";
import { Flex, DatePicker } from "antd";

const TitleText = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colorTextDesc};
`;

const FilterDate = ({
  title,
  onChange,
  disabledDate,
  onCalendarChange,
  onOpenChange,
}) => {
  const { RangePicker } = DatePicker;

  return (
    <Flex justify="space-between" align="center">
      <TitleText>{title}</TitleText>
      <RangePicker
        onChange={onChange}
        onCalendarChange={onCalendarChange}
        onOpenChange={onOpenChange}
        disabledDate={disabledDate}
        changeOnBlur
      />
    </Flex>
  );
};

export default FilterDate;
