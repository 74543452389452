/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Card, Flex, Row, Col } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import CampaignProvider from "../provider/campaignProvider";
import TitleCampaign from "../TitleCampaign";
import TrackingChart from "../../chartSocial/TrackingChart";
import LinkFooterCard from "../../LinkFooterCard";

const TrackingTab = ({ showColumn }) => {
  const { type } = useParams();

  const campaignProvider = CampaignProvider();
  const [data, setData] = useState([]);

  const fetchBarChartData = async () => {
    try {
      const barChartData = await campaignProvider.getAllCampaign("bar");

      setData(barChartData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchBarChartData();
  }, [showColumn]);

  return (
    <Row gutter={[16, 16]}>
      {data &&
        data.map((e, index) => (
          <Col span={showColumn} key={index}>
            <Card>
              <Flex gap={16} vertical>
                <TitleCampaign
                  showColumn={showColumn}
                  title={e.name}
                  date={moment(e.start_date).format("DD MMM YYYY")}
                  campaignId={e.id}
                  type={type}
                />{" "}
                <TrackingChart data={e?.data} name={e.name} />
                <LinkFooterCard title={e.name} campaignId={e.id} />
              </Flex>
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export default TrackingTab;
