/* eslint-disable object-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */

const platformOrder = {
  facebook: 1,
  twitter: 2,
  tiktok: 3,
  pantip: 5,
  internet: 4,
  youtube: 6,
};

const calculatedTime = (timeUpdated) => {
  const currentTime = new Date();
  const updateTime = new Date(timeUpdated);
  const timeDiff = (currentTime - updateTime) / (1000 * 60 * 60);
  if (Math.floor(timeDiff) < 1) {
    return "ล่าสุด";
  }
  if (Math.floor(timeDiff) < 24) {
    return `${Math.floor(timeDiff)} ชั่วโมง`;
  }
  if (Math.floor(timeDiff / 24) < 30) {
    return `${Math.floor(timeDiff / 24)} วัน`;
  }
  if (Math.floor(timeDiff / 24 / 30) < 12) {
    return `${Math.floor(timeDiff / 24 / 30)} เดือน`;
  }
  return `${Math.floor(timeDiff / 24 / 30 / 12)} ปี`;
};

const formatTime = (timeUpdated) => {
  const updateTime = new Date(timeUpdated);

  const dateFormatter = new Intl.DateTimeFormat("th-TH", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const formattedDate = dateFormatter.format(updateTime);
  const timeFormatter = new Intl.DateTimeFormat("th-TH", {
    hour: "numeric",
    minute: "numeric",
  });
  const formattedTime = timeFormatter.format(updateTime);

  return `${formattedDate} ${formattedTime}`;
};

const formatNumber = (number) => {
  if (number < 1e3) {
    return number.toString();
  }
  if (number < 1e6) {
    return `${(number / 1e3).toFixed(1)}K`;
  }
  if (number < 1e9) {
    return `${(number / 1e6).toFixed(1)}M`;
  }
  return `${(number / 1e9).toFixed(1)}B`;
};

const capitalize = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

const sortByPlatformAndLevels = (dataSort) => {
  return dataSort.sort((a, b) => {
    if (platformOrder[a.platform] < platformOrder[b.platform]) return -1;
    if (platformOrder[a.platform] > platformOrder[b.platform]) return 1;

    if (a.levels === "Minor" && b.levels === "Moderate") return -1;
    if (a.levels === "Moderate" && b.levels === "Minor") return 1;

    if (a.levels < b.levels) return 1;
    if (a.levels > b.levels) return -1;

    return 0;
  });
};

const sortByPlatform = (dataSort) => {
  return dataSort.sort((a, b) => {
    if (platformOrder[a.platform] < platformOrder[b.platform]) return -1;
    if (platformOrder[a.platform] > platformOrder[b.platform]) return 1;

    return 0;
  });
};

export {
  calculatedTime,
  formatTime,
  formatNumber,
  capitalize,
  sortByPlatformAndLevels,
  sortByPlatform,
};
