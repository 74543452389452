/* eslint-disable import/no-extraneous-dependencies */
import { createContext, useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useProviderAuth";

const AxiosContext = createContext();

const useProviderAxios = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const campaignId = url.split("/")[4];
  const campaignIdCookies = Cookies.get("campaignId");
  const { signout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (config) => {
      setIsLoading(true);
      const token = Cookies.get("token") || null;
      if (token != null) config.headers.Authorization = `${token}`;
      if (campaignId)
        config.params = {
          campaign_id: campaignId,
        };
      else if (campaignIdCookies) {
        config.params = {
          campaign_id: campaignIdCookies,
        };
      }

      return config;
    },
    (error) => {
      // console.error("Request Interceptor Error:", error);
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return response;
    },
    (error) => {
      // console.error("Response Interceptor Error:", error);
      const err = error.response;

      if (err?.status === 401) {
        signout(() => navigate("/login"));
      }

      if (err?.status === 403) {
        switch (err?.data) {
          case "Permission Denied":
            navigate(`/dashboard/${campaignIdCookies}`);
            window.location.reload();
            break;
          case "This tab is not allow for you":
            break;
          default:
            break;
        }
      }

      if (err?.status === 422) {
        setIsLoading(false);
        return Promise.reject(err.data);
      }

      if (err?.status === 500) {
        const errorMessage = new Error("Server Error");
        return Promise.reject(errorMessage);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return Promise.reject(err);
    },
  );

  return {
    axiosInstance,
    isLoading,
  };
};

const useAxios = () => {
  const { axiosInstance, isLoading } = useContext(AxiosContext);
  return {
    axios: axiosInstance,
    isLoading,
  };
};
const AxiosProvider = ({ children }) => {
  const instanceRef = useProviderAxios();

  return (
    <AxiosContext.Provider value={instanceRef}>
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosProvider, useAxios };
