import { Button, Flex, Form, Input } from "antd";
import styled from "styled-components";

export const ThemeStyledInput = styled(Input)`
  &:hover {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
  &:focus {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
`;

export const ThemeStyledButton = styled.div`
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: ${(props) => props.theme.colorcheckbox};
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
`;

const InputKeyword = ({ formKeyword, handleAdd, isEdit, handleEdit }) => {
  const handleSubmit = (value) => {
    if (value.mainKeyword.trim().length > 0) {
      if (isEdit) {
        handleEdit(value.mainKeyword.trim());
      } else {
        handleAdd(value.mainKeyword.trim());
        formKeyword.setFieldValue("mainKeyword", "");
      }
    }
  };

  return (
    <div
      id="input-keyword"
      style={{
        width: "40%",
      }}
    >
      <Flex vertical>
        <span
          className="input-keyword-header"
          style={{
            fontSize: "16px",
            fontWeight: 700,
            marginBottom: "16px",
          }}
        >
          Input MainKeyword
        </span>
        <Form form={formKeyword} onFinish={handleSubmit}>
          <Form.Item
            label="Campaign Name"
            name="mainKeyword"
            rules={[
              {
                required: true,
                message: "Please enter Campaign Name",
              },
            ]}
            style={{
              marginBottom: "16px",
            }}
          >
            <ThemeStyledInput placeholder="Enter Campaign Name" id="TEST" />
          </Form.Item>
          <Form.Item>
            <Flex justify="flex-end">
              <ThemeStyledButton>
                <Button htmlType="submit">{isEdit ? "EDIT" : "ADD"}</Button>
              </ThemeStyledButton>
            </Flex>
          </Form.Item>
        </Form>
      </Flex>
    </div>
  );
};

export default InputKeyword;
