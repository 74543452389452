/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
import IconAround from "../../assets/icons/icon-around.svg";
import IconIn from "../../assets/icons/icon-in.svg";
import IconOut from "../../assets/icons/icon-out.svg";

const options = [
  {
    value: "facebook",
    label: "Facebook",
  },
  {
    value: "twitter",
    label: "Twitter(x)",
  },
  {
    value: "tiktok",
    label: "Tiktok",
  },
  {
    value: "pantip",
    label: "Pantip",
  },
  {
    value: "internet",
    label: "Internet",
  },
  {
    value: "youtube",
    label: "Youtube",
  },
];

const arrowOptions = [
  {
    value: "in",
    id: "in",
    icon: IconIn,
    label: "",
  },
  {
    value: "out",
    id: "out",
    icon: IconOut,
    label: "",
  },
  {
    value: "around",
    id: "around",
    icon: IconAround,
    label: "",
  },
];

const importImageOption = [
  {
    value: "noImage",
    id: "no_image",
    label: "No Image",
  },
  {
    value: "upload",
    id: "upload_value",
    label: "Upload",
  },
  {
    value: "import",
    id: "import_value",
    label: "Link Image",
  },
];

const sentimentOptions = [
  {
    id: "strong-negative",
    value: "strong-negative",
    label: "Strong Negative",
  },
  {
    id: "weak-negative",
    value: "weak-negative",
    label: "Weak Negative",
  },
  {
    id: "natural",
    value: "natural",
    label: "Natural",
  },
  {
    id: "weak-positive",
    value: "weak-positive",
    label: "Weak Positive",
  },
  {
    id: "strong-positive",
    value: "strong-positive",
    label: "Strong Positive",
  },
];

const mapSentimentValue = (value) => {
  const mapping = {
    "strong-negative": {
      positive_trend: 0,
      negative_trend: 100,
    },
    "weak-negative": {
      positive_trend: 25,
      negative_trend: 75,
    },
    "natural": {
      positive_trend: 50,
      negative_trend: 50,
    },
    "weak-positive": {
      positive_trend: 75,
      negative_trend: 25,
    },
    "strong-positive": {
      positive_trend: 100,
      negative_trend: 0,
    },
  };

  if (typeof value === "object") {
    for (const key in mapping) {
      if (JSON.stringify(mapping[key]) === JSON.stringify(value)) {
        return key;
      }
    }
  }

  if (typeof value === "string") {
    return mapping[value];
  }

  return null;
};

const sentimentTypeColor = (value) =>{
  switch (value) {
    case 'strong-negative':
      return "#FF2643";
    case 'weak-negative':
      return "#FF8126";
    case 'natural':
      return "#C0C0C0";
    case 'weak-positive':
      return "#92BE17";
    case 'strong-positive':
      return "#0EA67F";
    default:
      return "#C0C0C0"; 
  }
}

const optionsFilterStatus = [
  {
    value: 0,
    label: "รออนุมัติ",
  },
  {
    value: 1,
    label: "อนุมัติ",
  }
];

export { optionsFilterStatus, options, arrowOptions, sentimentOptions, mapSentimentValue, sentimentTypeColor,importImageOption };
