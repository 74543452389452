/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Flex, Select } from "antd";
import moment from "moment";
import { ThemeStyledSelect } from "../../../common/components/ThemeStyledComponent";
import CampaignProvider from "../../dashboard/campaignDashboard/provider/campaignProvider";

const TitleText = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colorTextDesc};
`;

const TitleListLayout = styled(Flex)`
  padding: 8px 16px 0px 16px;
`;
const { Option } = Select;

const TitleList = ({ title, campaignId, onChageCampaign }) => {
  const campaignProvider = CampaignProvider();
  const [campaignOption, setcampaignOption] = useState([]);

  const fetchcampaignOption = async () => {
    try {
      const campaignData = await campaignProvider.getVersusCampaign();

      if (campaignData) {
        const selectAllOption = {
          id: 0,
          _id: "all",
          name: "เลือกทั้งหมด",
          start_date: "2023-03-30T17:00:00.000Z",
        };

        campaignData.push(selectAllOption);
        setcampaignOption(campaignData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchcampaignOption();
  }, []);

  return (
    <TitleListLayout align="center" gap={39}>
      <TitleText>{title}</TitleText>
      <Flex align="center" gap={18}>
        <span>Campaign : </span>
        <ThemeStyledSelect
          value={campaignId}
          placeholder="Please select campaign"
          onChange={onChageCampaign}
          style={{
            width: "216px",
          }}
        >
          {campaignOption &&
            campaignOption?.map((item) => (
              <Option key={item.id} value={item?._id}>
                {item.name}
              </Option>
            ))}
        </ThemeStyledSelect>
      </Flex>
    </TitleListLayout>
  );
};

export default TitleList;
