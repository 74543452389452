import { combineSlices } from "@reduxjs/toolkit";
import modalErrorSlice from "../common/components/ModalError/modalErrorSlice";
import siebarSlice from "../common/components/Sidebar/siderSlice";
import filterListSlice from "../features/listTrend/filterListSlice";
import chartsSlice from "../features/dashboard/chartSocial/chartSlice";
import SocialCardSlice from "../features/dashboard/socialCard/socialCardSlice";
import insertTrendSlice from "../features/InsertTrend/insertTrendSlice";
import campaignsSlice from "../features/dashboard/campaignDashboard/campaignSlice";

const rootReducer = combineSlices({
  chart: chartsSlice,
  campaignState: campaignsSlice,
  socialCard: SocialCardSlice,
  filterList: filterListSlice,
  sidebar: siebarSlice,
  modalError: modalErrorSlice,
  insertTrend: insertTrendSlice,
});

export default rootReducer;
