const theme = {
  heightCardSocial: "256px",
  white: "#FFF",
  backgroundDashBoard: "#EFEFF2",
  backgroundFilter: "#F4F4F4",
  colorTextDesc: "#444450",
  navbarHeight: "50px",

  // card
  colorBorderCardSocial: "#DBDBDB",
  colorBorderCardSocialRed: "#FF2643",
  colorTextInfo: "#448EF7",
  colorPositive: "#3FD495",
  colorNegative: "#FF2643",

  // button
  colorButtonDisable: "#DBDBDB",
  colorButtonBoost: "#0EA67F",
  colorButtonBoomb: "#FF2643",
  colorHoverButtonBoost: "#51C9AB",
  colorHoverButtonBomb: "#FF677C",
  shadowButton: " 1px 2px 4px 0px rgba(0, 0, 0, 0.25)",
  colorTextButton: "#2A2C2D",
  showColumnToggleBackground: "#D7CDF8",

  colorButtonUpload: "#705EF9",

  // toggle
  colorToggleDisable: "#AFAEAE",

  // checkbox
  colorcheckbox: "#8F79D7",

  // table
  colortitletable: "#252527",

  // Sidebar
  colorMagenta: "#7D5DE8",

  // News Archives
  colorButtonDelete: "#ED1E45",
  bgButtonDelete: "#FFEEEE",
  bgButtonAdd: "#E7F6F2",
};

export default theme;
