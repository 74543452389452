import React from "react";
import styled from "styled-components";

import { Flex, Tooltip } from "antd";
import iconX from "../../../../../assets/icons/icon-x.svg";
import iconFaceBook from "../../../../../assets/icons/icon-facebook-none-bg.svg";
import iconPantip from "../../../../../assets/icons/icon-pantip.svg";
import iconTikTok from "../../../../../assets/icons/icon-tiktok.svg";
import iconInternet from "../../../../../assets/icons/icon-internet.svg";
import iconYoutube from "../../../../../assets/icons/icon-youtube.svg";

const CircleProgressBarContainer = styled(Flex)`
  /* justify-content: center;
  align-items: center; */
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(
      ${(props) => props.progressColor} ${(props) => props.progress}%,
      ${(props) => (props.progress !== 0 ? "white" : "#D9D9D9")} 0
    );

  .img-platform {
    width: 40px;
    height: 40px;
  }
`;

const CircleProgressComponent = ({
  platform,
  positiveValue,
  negativeValue,
}) => {
  const checkLogo = (type) => {
    switch (type) {
      case "twitter":
        return iconX;
      case "facebook":
        return iconFaceBook;
      case "pantip":
        return iconPantip;
      case "tiktok":
        return iconTikTok;
      case "internet":
        return iconInternet;
      case "youtube":
        return iconYoutube;
      default:
        return iconInternet;
    }
  };
  const negativeColor = (percent) => {
    if (percent === 50) return "#D9D9D9";
    if (percent <= 40 && percent > 20) return "#FEB11A";
    if (percent <= 60 && percent > 40) return "#FE881A";
    if (percent <= 80 && percent > 60) return "#FE5F1A";
    if (percent <= 100 && percent > 80) return "#FF2643";
    return "#FEDA1A";
  };

  const positiveColor = (percent) => {
    if (percent === 50) return "#D9D9D9";
    if (percent <= 40 && percent > 20) return "#F1DE34";
    if (percent <= 60 && percent > 40) return "#CADB12";
    if (percent <= 80 && percent > 60) return "#92BE17";
    if (percent <= 100 && percent > 80) return "#0EA67F";
    return "#FEDA1A";
  };

  return (
    <Tooltip
      title={`Impact : ${
        positiveValue > negativeValue
          ? `Positive ${positiveValue}`
          : `Negative ${negativeValue}`
      } %`}
    >
      <CircleProgressBarContainer
        align="center"
        justify="center"
        progress={positiveValue > negativeValue ? positiveValue : negativeValue}
        progressColor={
          positiveValue > negativeValue
            ? positiveColor(positiveValue)
            : negativeColor(negativeValue)
        }
      >
        <img
          className="img-platform"
          src={checkLogo(platform)}
          alt={`Logo-${platform}`}
        />
      </CircleProgressBarContainer>
    </Tooltip>
  );
};

export default CircleProgressComponent;
