import React, { useEffect } from "react";
import { Chart } from "@antv/g2";
import { styled } from "styled-components";
import { Empty } from "antd";
import {
  formatNumber,
  capitalize,
  // sortByPlatformAndLevels,
} from "../../../../common/utils/format";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const RiskStackedBarChart = (props) => {
  const { data, idChart } = props;

  const calculate20Percent = (event) => {
    const maxCount = Math.max(...event.map((item) => item.trendCount));
    const twentyPercent = maxCount * 0.08;
    return twentyPercent;
  };

  useEffect(() => {
    if (data?.length > 0) {
      const chart = new Chart({
        container: `risk-stacked-bar-chart-${idChart}`,
        paddingTop: 10,
        height: 300,
        autoFit: true,
      });

      chart
        .interval()
        .data(data)
        .transform({
          type: "stackY",
        })
        .encode("x", (d) => capitalize(d.platform))
        .encode("y", "trendCount")
        .encode("color", "levels")
        .axis("x", {
          labelSpacing: 4,
          title: false,
          line: true,
          labelOpacity: 1,
          labelFill: "#000",
        })
        .axis("y", {
          title: false,
          line: true,
          labelFormatter: "~s",
          labelOpacity: 1,
          labelFill: "#000",
        })
        .style("maxWidth", 70)
        .scale("color", {
          relations: [
            ["Negligible", "#62BB46"],
            ["Minor", "#B2D34A"],
            ["Moderate", "#FAD55E"],
            ["High", "#F49B25"],
            ["Critical", "#F15640"],
          ],
        })
        .scale("color", {
          domain: ["Negligible", "Minor", "Moderate", "High", "Critical"],
          range: ["#62BB46", "#B2D34A", "#FAD55E", "#F49B25", "#F15640"],
        })
        .label({
          text: "trendCount",
          position: "inside",
          formatter: (text, datum) =>
            text > calculate20Percent(data)
              ? `${formatNumber(datum.trendCount)}`
              : "",
          transform: [
            {
              type: "overlapDodgeY",
            },
          ],
          fill: "#000",
          fontSize: 10,
        })
        .legend({
          color: {
            position: "bottom",
            itemMarker: "circle",
            layout: {
              justifyContent: "center",
            },
          },
        });

      chart.render();
    }
  }, [data]);

  return (
    <div>
      <Title>Risk Data</Title>
      {data?.length > 0 ? (
        <div id={`risk-stacked-bar-chart-${idChart}`} />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default RiskStackedBarChart;
