import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  titleCampaign: "",
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setTitleCampaign: (state, action) => {
      state.titleCampaign = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTitleCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
