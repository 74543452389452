/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Card, Flex, Row, Col } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import WordCloud from "../../chartSocial/WordCloud";
import CampaignProvider from "../provider/campaignProvider";
import TitleCampaign from "../TitleCampaign";
import LinkFooterCard from "../../LinkFooterCard";

const TopKeywordsTab = ({ showColumn }) => {
  const { type } = useParams();

  const campaignProvider = CampaignProvider();
  const [data, setData] = useState([]);

  const fetchWordCloudData = async () => {
    try {
      const wordCloudData = await campaignProvider.getAllCampaign("wordcloud");
      setData(wordCloudData || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchWordCloudData();
  }, [showColumn]);

  return (
    <Row gutter={[16, 16]}>
      {data &&
        data.map((e, index) => (
          <Col span={showColumn} key={index}>
            <Card>
              <Flex gap={16} vertical>
                <TitleCampaign
                  showColumn={showColumn}
                  title={e.name}
                  date={moment(e.start_date).format("DD MMM YYYY")}
                  campaignId={e.id}
                  type={type}
                />
                <WordCloud data={e.data} idChart={index} />
              </Flex>
              <LinkFooterCard title={e.name} campaignId={e.id} />
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export default TopKeywordsTab;
