/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Chart } from "@antv/g2";
import { styled } from "styled-components";
import { Flex } from "antd";
// import { formatNumber } from "../../../../common/utils/format";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const TitleDate = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #40b5ad;
`;

const VersusChart = (props) => {
  const { data, typeChart, dateTime, title } = props;

  useEffect(() => {
    const chart = new Chart({
      container: `versus-chart-${typeChart}-${dateTime}`,
      paddingTop: 10,
      height: 300,
      // width: 400,
      autoFit: true,
    });

    chart
      .interval()
      .data(data)
      .encode("x", "social")
      .encode("y", "sentiment")
      .encode("z", "counts")
      .encode("color", "name")
      .scale("color", {
        range: ["#32CD32", "#FF5733"],
      })
      .scale("y", {
        type: "linear",
        domain: [0, 5],
        // range: [0, 1],
      })
      .transform({
        type: "dodgeX",
      })
      .transform({
        type: "sortX",
        by: "y",
        reverse: true,
      })

      .label({
        text: "counts",
        x: 0,
        y: -20,
        formatter: (z, x) =>
          z ? `${typeChart === "replies" ? `💬${z}` : z}` : "",
        transform: [
          {
            type: "overlapHide",
          },
        ],
        fill: "#000",
        fontSize: 12,
      })
      .label({
        text: "sentiment",
        position: "top",
        dy: 15,
        render: (text, datum) => {
          return parseFloat(datum.sentiment) > 0.5
            ? `<span style="left:-50%;top:10px;position:relative;font-size:12px;font-weight: bold;">${datum.sentiment.toFixed(
                2,
              )}</span>`
            : `<span></span>`;
        },
        // formatter: (y) => parseFloat(y),
      })
      .axis("y", {
        title: false,
      })
      .axis("x", {
        title: false,
      })
      .legend({
        color: {
          position: "bottom",
          layout: {
            justifyContent: "center",
          },
        },
      })
      // .tooltip({
      //   channel: "n",
      //   valueFormatter: (n) => (n ? `${n}` : ""),
      // })
      .tooltip({
        channel: "z",
        valueFormatter: (z) => (z ? `💬 ${z}` : ""),
      })
      .tooltip({
        channel: "y",
        valueFormatter: "~s",
      });
    // .interaction("elementHighlight", {
    //   background: true,
    // });

    chart.render();
  }, [data]);

  return (
    <div>
      <Title>{title}</Title>

      <Flex justify="center" align="center">
        <TitleDate>{dateTime}</TitleDate>
      </Flex>
      <div id={`versus-chart-${typeChart}-${dateTime}`} />
    </div>
  );
};

export default VersusChart;
