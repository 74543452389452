/* eslint-disable no-undef */
import { useAxios } from "../../../../common/hooks/useProviderAxios";
// import { mockCampaign } from "./mockCampaign";

const host = process.env.REACT_APP_API_ENDPOINT;

const campaignProvider = () => {
  const { axios } = useAxios();

  const getAllProject = async () => {
    try {
      const url = `${host}/projects?type=${type}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get project", error);
      return [];
    }
  };

  const getAllCampaign = async (type) => {
    try {
      const url = `${host}/campaigns/dashboard?type=${type}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get campaign", error);
      return [];
    }
  };

  const getVersusCampaign = async () => {
    try {
      const url = `${host}/campaigns/versus`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get campaign", error);
      return [];
    }
  };

  const versusCampaign = async (body) => {
    try {
      // if (mockCampaign) return mockCampaign;
      const url = `${host}/campaigns/versus`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      if (error.code === "ER_BAD_FIELD_ERROR") {
        throw error;
      }
      return error;
    }
  };

  return {
    getAllProject,
    getAllCampaign,
    versusCampaign,
    getVersusCampaign,
  };
};

export default campaignProvider;
