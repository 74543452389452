/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Card, Flex, Row, Col } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import CardSentiment from "./CardSentiment";
import TitleCampaign from "../TitleCampaign";
import CampaignProvider from "../provider/campaignProvider";
import LinkFooterCard from "../../LinkFooterCard";

const SentimentTab = ({ showColumn }) => {
  const { type } = useParams();

  const campaignProvider = CampaignProvider();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const res = await campaignProvider.getAllCampaign("sentiment");
      setData(res);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [showColumn]);

  return (
    <Row gutter={[16, 16]}>
      {data &&
        data.map((items, index) => (
          <Col span={showColumn} key={index}>
            <Card>
              <Flex vertical gap={10}>
                <TitleCampaign
                  showColumn={showColumn}
                  title={items.name}
                  date={moment(items.start_date).format("DD MMM YYYY")}
                  campaignId={items.id}
                  type={type}
                />
                <Flex vertical gap={10}>
                  {items.data &&
                    items.data.map((e) => (
                      <CardSentiment
                        platform={e.platform}
                        pinned={Boolean(e.pinned)}
                        title={e.title}
                        positiveValue={e.positive_trend}
                        negativeValue={e.negative_trend}
                      />
                    ))}
                </Flex>
                <LinkFooterCard title={items.name} campaignId={items.id} />
              </Flex>
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export default SentimentTab;
