/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import styled from "styled-components";
import { Breadcrumb, Button, Flex } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import TabCampaignDashboard from "../features/dashboard/campaignDashboard";
import FilterSocial from "../features/dashboard/filterPlatform";
import MainLayout from "../common/layouts/main";

const StyleDashBoard = styled(Flex)`
  padding: 16px;
  background: ${(props) => props.theme.backgroundDashBoard};
`;

const StyleButton = styled(Button)`
  background-color: ${(props) => props.theme.colorButtonBoost};
`;
const CampaignsDashboard = () => {
  // const navigate = useNavigate();
  const { type } = useParams();
  const res = {
    config: {
      api: {
        key: ";lmpmpoko",
        allow: true,
        token: "uiytrtyop[",
      },
      organic: {
        allow: false,
      },
      web_cookie: {
        allow: true,
        cookie: "swqqwsqs",
      },
    },
  };

  const allowedKeys = Object.keys(res.config).filter(
    (key) => res.config[key].allow,
  );

  console.log(allowedKeys);

  return (
    <MainLayout>
      <StyleDashBoard id="dashboard" gap={16} vertical>
        {type === "campaign" ? (
          <Breadcrumb>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a
                // href="/campaign-dashboard"
                style={{
                  color: "#7D5DE8",
                }}
              >
                Campaign
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          <></>
        )}
        <FilterSocial title={type === "project" ? "Dashboard" : "Campaingn"}>
          {/* <StyleButton
            type="primary"
            onClick={() => navigate("/manage-campaigns")}
          >
            {type === "project" ? "New Project" : "Create Campaingn"}
          </StyleButton> */}
        </FilterSocial>
        <TabCampaignDashboard />
      </StyleDashBoard>
    </MainLayout>
  );
};

export default CampaignsDashboard;
