import { Flex } from "antd";
// import Layout from "antd/es/layout/layout";
// import styled from "styled-components";
// import { useDispatch, useSelector } from "react-redux";
// import { setIsCollapse } from "../../components/sider/siderSlice";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
// import Sidebar from "../../components/Sidebar";

// const StyledSidebar = styled.div`
//   .ant-menu-item-selected,
//   .ant-menu-item {
//     width: 100%;
//     border-radius: 0;
//     margin: 0 !important;
//   }

//   .ant-menu-item {
//     display: flex;
//     justify-content: center;
//   }

//   .ant-menu-title-content {
//     height: 100% !important;
//   }

//   .ant-menu-light .ant-menu-item-selected {
//     color: white !important;
//     background-color: ${(props) => props.theme.colorMagenta} !important;
//     /* font-family: "Noto Sans Thai"; */
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 22px;
//     letter-spacing: 0.15px;
//   }

//   .ant-menu-light:not(.ant-menu-horizontal)
//     .ant-menu-item:not(.ant-menu-item-selected):hover {
//     background-color: ${(props) => props.theme.colorMagenta} !important;
//     color: white !important;

//     width: 100%;
//     border-radius: 0;
//     margin: 0;
//   }

//   .ant-menu-light:not(.ant-menu-horizontal)
//     .ant-menu-item:not(.ant-menu-item-selected):active {
//     background-color: transparent;
//   }

//   .ant-menu-inline-collapsed-tooltip {
//     display: none;
//   }

//   #sidebar .ant-tooltip {
//     display: none;
//   }
// `;

const MainLayout = ({ children }) => {
  // const dispatch = useDispatch();
  // const { isCollapse } = useSelector((state) => state.sidebar);

  // const toggleSidebar = () => {
  //   dispatch(setIsCollapse());
  // };

  return (
    <Flex vertical>
      {/* WITH SIDE BAR */}
      {/* <Navbar toggleSidebar={toggleSidebar} /> */}
      {/* <StyledSidebar>
        <Layout>
          <Sidebar id="sidebar" isCollapse={isCollapse} />
          {children}
        </Layout>
      </StyledSidebar> */}

      {/* MO SIDE BAR */}
      <Navbar />
      {children}
      <Footer />
    </Flex>
  );
};

export default MainLayout;
