/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { Flex, Empty, Card } from "antd";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const TitleDate = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #40b5ad;
`;

const CardStyle = styled(Card)`
  padding: 10px;
`;

const EmptyCard = ({ title, dateTime }) => {
  return (
    <CardStyle>
      <Title>{title}</Title>
      <Flex justify="center" align="center" vertical gap={16}>
        <TitleDate>{dateTime}</TitleDate>
      </Flex>
      <Flex justify="center" align="center" vertical gap={16}>
        <Empty
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "300px",
          }}
        />
      </Flex>
    </CardStyle>
  );
};

export default EmptyCard;
