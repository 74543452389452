/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from "react";
import {
  Button,
  ColorPicker,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Space,
} from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import keywordProvider from "../provider/keywordProvider";

const { RangePicker } = DatePicker;

const ModalWrap = styled(Modal)`
  .ant-modal {
    width: 800px !important;
  }
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    padding: 16px;
  }

  .ant-modal-body {
    padding: 16px;
  }

  .ant-modal-footer {
    padding: 16px;
    text-align: center;
  }
`;

const ButtonCustom = styled(Button)`
  width: 132px;
  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const KeywordModal = ({ data, closeModal, isOpen, getData, type }) => {
  const {
    name,
    start_date: oldStartDate,
    end_date: oldEndDate,
    background_color: color,
  } = data || {};
  const keywordApi = keywordProvider();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formatHex, setFormatHex] = useState("hex");

  useEffect(() => {
    if (data && type === "edit") {
      form.setFieldValue("color", color);
      form.setFieldValue("name", name);
      form.setFieldValue(
        "date",
        (oldStartDate &&
          oldEndDate && [dayjs(oldStartDate), dayjs(oldEndDate)]) ||
          "",
      );
    } else {
      form.setFieldValue("color", "#ffffff");
      form.setFieldValue("name", "");
      form.setFieldValue("date", "");
    }
  }, [form, data, type]);

  // eslint-disable-next-line consistent-return
  const createKeyword = async (body) => {
    return new Promise((resolve, reject) => {
      try {
        setLoading(true);
        const payload = {
          body,
        };
        const res = keywordApi.addListKeyword(payload);
        resolve(res);
        closeModal();
      } catch (error) {
        reject(error);
      } finally {
        setLoading(false);
      }
    });
  };

  const editKeyword = async (body) => {
    return new Promise((resolve, reject) => {
      try {
        setLoading(true);
        const payload = {
          id: data?.id,
          body,
        };
        const res = keywordApi.editListKeyword(payload);
        resolve(res);
        closeModal();
      } catch (error) {
        reject(error);
      } finally {
        setLoading(false);
      }
    });
  };

  const onSubmit = async (val) => {
    try {
      const body = {
        name: val.name,
        start_date: val.date[0].format(),
        end_date: val.date[1].format(),
        background_color: val.color,
      };
      if (type === "create") {
        await createKeyword(body);
        getData();
      } else {
        await editKeyword(body);
        getData();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onCancel = () => {
    closeModal();
  };

  return (
    <ModalWrap
      width={736}
      title="New Campaign"
      open={isOpen}
      footer={false}
      onCancel={closeModal}
    >
      <Form
        form={form}
        name="campaign"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: "100%",
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Campaign Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your Campaign name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Period"
          name="date"
          rules={[
            {
              required: true,
              message: "Please input your Date!",
            },
          ]}
        >
          <RangePicker showTime />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          rules={[
            {
              required: true,
              message: "Please input your Color!",
            },
          ]}
        >
          <ColorPicker
            showText
            onChange={(e) => form.setFieldsValue({ color: e.toHexString() })}
          />
        </Form.Item>

        <Flex
          direction="row"
          gap={8}
          justify="center"
          style={{ width: "100%" }}
        >
          <ButtonCustom key="cancel" onClick={() => onCancel()}>
            Cancel
          </ButtonCustom>
          <ButtonCustom type="primary" htmlType="submit" loading={loading}>
            Confirm
          </ButtonCustom>
        </Flex>
      </Form>
    </ModalWrap>
  );
};

KeywordModal.defaultProps = {
  type: "create",
  name: "title",
  startDate: dayjs(),
  endDate: dayjs(),
  color: "#1677FF",
  modal: false,
};

export default KeywordModal;
