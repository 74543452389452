/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import styled from "styled-components";
// import { setKeySidebar } from "../common/components/sider/siderSlice";
// import { useParams } from "react-router-dom";
import { Breadcrumb, Flex } from "antd";
import { useState } from "react";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import FilterSocial from "../features/dashboard/filterPlatform";
import Charts from "../features/dashboard/chartSocial";
import SocialCard from "../features/dashboard/socialCard";
import MainLayout from "../common/layouts/main";
import { StyledTabs } from "../common/components/ThemeStyledComponent";
import RiskManagement from "../features/dashboard/riskManagement";
import CollapseRight from "../assets/icons/chevron-right.svg";
import CollapseLeft from "../assets/icons/chevron-left.svg";

const StyleDashBoardTab = styled.div`
  padding: 16px 0px;
  background: ${(props) => props.theme.backgroundDashBoard};
`;

const DashBoard = () => {
  const { titleName } = useSelector((state) => state.socialCard);
  const checkTab = Cookies.get("checkTab");
  const [hideSentiment, setHideSentiment] = useState(false);
  // const dispatch = useDispatch();
  // const { isCollapse } = useSelector((state) => state.sidebar);

  const StyleDashBoard = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 4px 16px;
    background: ${(props) => props.theme.backgroundDashBoard};
    z-index: 999;
  `;

  const StyleDashBoardSpliter = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 8px;
    margin-bottom: 4px;
    height: calc(100dvh - 132px);

    > div {
      width: 49%;
      height: inherit;
      overflow: auto;
    }

    .ant-tabs-content-holder {
      height: calc(100dvh - 200px);
      overflow-y: hidden;
      transition: background-color 0.3s ease;
    }

    .ant-tabs-content-holder:hover {
      background: ${(props) => props.theme.backgroundDashBoard};
      height: calc(100dvh - 200px);
      overflow-y: auto;
      display: ${(props) => props.display};
      background: ${(props) => props.theme.backgroundDashBoard};
      transition: background-color 0.3s ease;
    }
  `;

  const ToggleCollapseRight = styled.div`
    display: flex;
    width: 15px;
    height: 48px;
    padding: 8.703px;
    justify-content: center;
    align-items: center;
    gap: 8.703px;
    position: absolute;
    right: 2px;
    top: 99.133px;
    border-radius: 0px 6px 6px 0px;
    border: 1.088px solid #d9d9d9;
    background: #cbcbcb;
    z-index: -1;
    cursor: pointer;
  `;

  const ToggleCollapseLeft = styled.div`
    display: flex;
    width: 20px;
    height: 48px;
    padding: 8.703px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -0.615px;
    top: 99.133px;
    border-radius: 6px 0px 0px 6px;
    border: 1.088px solid #d9d9d9;
    background: #cbcbcb;
    /* z-index: -1; */
    cursor: pointer;
  `;

  return (
    <MainLayout>
      {checkTab === "tab allow" ? (
        <StyleDashBoard id="dashboard">
          <Breadcrumb>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            {/* <Breadcrumb.Item>
              <a href="/project-dashboard">Project</a>
            </Breadcrumb.Item> */}
            <Breadcrumb.Item>
              <a
                // href="/campaign-dashboard"
                style={{
                  color: "#7D5DE8",
                }}
              >
                Data Visualization
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <FilterSocial
            title={
              titleName
                ? `Data Visualization : ${titleName}`
                : "Data Visualization"
            }
          />
          <StyleDashBoardSpliter>
            {hideSentiment ? (
              <>
                <Charts />
                <RiskManagement />
              </>
            ) : (
              <>
                <StyledTabs
                  centered
                  defaultActiveKey="1"
                  items={[
                    {
                      label: "Social Listening",
                      key: "1",
                      children: (
                        <StyleDashBoardTab>
                          <Charts />
                        </StyleDashBoardTab>
                      ),
                    },
                    {
                      label: "Risk Management",
                      key: "2",
                      children: (
                        <StyleDashBoardTab>
                          <RiskManagement />
                        </StyleDashBoardTab>
                      ),
                    },
                  ]}
                />
                <SocialCard />
              </>
            )}
          </StyleDashBoardSpliter>
          {hideSentiment ? (
            <ToggleCollapseLeft
              onClick={() => setHideSentiment(!hideSentiment)}
            >
              <img src={CollapseLeft} alt="icon-collapse-right" />
            </ToggleCollapseLeft>
          ) : (
            <ToggleCollapseRight
              onClick={() => setHideSentiment(!hideSentiment)}
            >
              <img src={CollapseRight} alt="icon-collapse-right" />
            </ToggleCollapseRight>
          )}
        </StyleDashBoard>
      ) : (
        <StyleDashBoard id="dashboard">
          <FilterSocial
            title={
              titleName
                ? `Data Visualization : ${titleName}`
                : "Data Visualization"
            }
          />
          <StyleDashBoardSpliter>
            <Charts />
            <SocialCard />
          </StyleDashBoardSpliter>
        </StyleDashBoard>
      )}
    </MainLayout>
  );
};

export default DashBoard;
