/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Flex } from "antd";
import { Link } from "react-router-dom";
import { setKeySocialCard } from "../../socialCard/socialCardSlice";

// import { useNavigate } from "react-router-dom";

// const StyledButton = styled(Button)`
//   font-size: 16px;
//   font-weight: 700;
// `;

const TitleText = styled(Link)`
  font-size: 24px;
  font-weight: 700;
  text-decoration-line: underline;
  /* color: ${(props) => props.theme.colorTextDesc}; */
`;

const DateText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colorTextDesc};
`;

const TitleCampaign = ({ title, date, campaignId, showColumn, type }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    const payload = {
      key: "titleName",
      value: title,
    };
    dispatch(setKeySocialCard(payload));
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex
        vertical
        style={{
          width: "300px",
        }}
      >
        <TitleText
          to={{
            pathname:
              type === "campaign"
                ? `/dashboard/${campaignId}`
                : `/campaign-dashboard/${campaignId}`,
          }}
          onClick={handleClick}
        >
          {showColumn === 8
            ? title.length > 19
              ? `${title.slice(0, 20)}...`
              : title
            : title}
        </TitleText>

        <DateText>Campaign Start : {date}</DateText>
      </Flex>
      {/* <Flex>
        <StyledButton
          type="link"
          danger
          onClick={() => navigate(`/dashboard/${campaignId}`)}
        >
          {"Look up >"}
        </StyledButton>
      </Flex> */}
    </Flex>
  );
};

export default TitleCampaign;
