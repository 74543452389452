/* eslint-disable radix */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Flex, Select, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import CircleProgressComponent from "../../dashboard/campaignDashboard/SentimentTab/CicleProgressComponent";
import {
  StyledPagination,
  StyledButtonIcon,
} from "../../../common/components/ThemeStyledComponent";
import { formatNumber } from "../../../common/utils/format";
import ModalConfirmComponent from "../../../common/components/ModalComponent";
import ListTrendProvider from "../provider/listTrendProvider";
import CommentProvider from "../../comment/provider/commentProvider";
import { keysIcons } from "../../../common/utils/keyDatas";
import ChartProvider from "../../dashboard/provider/chartProvider";
import InputCommentForm from "../../comment/Form";

const titleData = [
  {
    title: "Platform",
  },
  // {
  //   title: "Campaign",
  // },
  {
    title: "Title",
  },
  {
    title: "Url",
  },
  {
    title: "Views",
  },
  {
    title: "Shares",
  },
  {
    title: "Likes",
  },
  {
    title: "Comments",
  },
  {
    title: "Effect",
  },
  {
    title: "Status",
  },
  {
    title: "Post date",
  },
  {
    title: "Update date",
  },
];

const optionsStatus = [
  {
    value: 0,
    label: "รออนุมัติ",
  },
  {
    value: 1,
    label: "อนุมัติ",
  },
  // {
  //   value: 2,
  //   label: "รอตรวจสอบ",
  // },
];

const StyledDiv = styled.div`
  @media (max-width: 1000px) or (orientation: portrait) {
    width: calc(100vw - 64px);
    overflow-x: auto;
    border-radius: 10px;
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  tr {
    display: grid;
    grid-template-columns: 5% auto 15% 5% 5% 5% 5% 5% 10% 10% 10% 10%;
  }
`;

const StyledTableCell = styled.td`
  background-color: ${(props) => props.theme.white};
  padding: 8px;
  word-break: break-all;

  a {
    width: 250px;
    word-break: break-all;
    overflow-wrap: break-word;
    display: block;
  }
`;

const TextTable = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledTableHeader = styled.th`
  padding: 8px;
  background-color: ${(props) => props.theme.colortitletable};
  color: ${(props) => props.theme.white};
`;

const StyledEvenRow = styled.tr`
  background-color: #f2f2f2;
`;

const StyledHoverRow = styled.tr`
  &:hover {
    background-color: #ddd;
  }
`;

const StyledStatus = styled(Select)`
  width: 110px !important;
  .ant-select-selector {
    border: 0px solid #d9d9d9 !important;
  }

  .ant-select-selection-item {
    font-size: 14px;
    color: ${(props) => props.color};
  }

  .ant-select-arrow {
    color: #0000008a;
  }
`;

const ListTable = ({
  filterStatus,
  tabType,
  isModalComment,
  setIsModalComment,
  setSelectCampaign,
}) => {
  const navigate = useNavigate();
  const { pageUrl, campaignId } = useParams();

  const listTrendProvider = ListTrendProvider();
  const commentProvider = CommentProvider();
  const chartProvider = ChartProvider();
  const { filter, sortby } = useSelector((state) => state.filterList);
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentId, setCurretId] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isModalComment, setIsModalComment] = useState(false);
  const [commentParent, setCommentParent] = useState(0);
  const pageSize = 10;

  const handlePageChange = (page) => {
    if (tabType === "post")
      navigate(`/list-post/${campaignId || "campaignId"}/${page}`);
    else navigate(`/list-comment/${campaignId || "campaignId"}/${page}`);
  };

  const fetchcheckPermission = async () => {
    await chartProvider.checkPermission();
  };

  const fetchPostData = async () => {
    try {
      const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

      const platformString = filterPlatform
        .map((platform) => `platform=${platform}`)
        .join("&");

      let params = `?&${platformString}&sort=${sortby}`;
      if (filterStatus === 2) {
        params += "&approved=1&approved=0";
      } else if (filterStatus === 1) {
        params += "&approved=1";
      } else {
        params += "&approved=0";
      }
      const res = await listTrendProvider.getListTrend(params);
      if (res?.data) {
        const data = res?.data?.filter((e) => !!e);
        setNewsData(data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchCommentData = async () => {
    try {
      const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

      const platformString = filterPlatform
        .map((platform) => `platform=${platform}`)
        .join("&");

      let params = `?&${platformString}&sort=${sortby}`;
      if (filterStatus === 2) {
        params += "&approved=1&approved=0";
      } else if (filterStatus === 1) {
        params += "&approved=1";
      } else {
        params += "&approved=0";
      }
      const res = await listTrendProvider.getListReply(params);
      if (res) {
        const data = res.filter((e) => !!e);
        setNewsData(data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    if (pageUrl) {
      setCurrentPage(parseInt(pageUrl));
    } else setCurrentPage(1);
  }, [pageUrl]);

  useEffect(() => {
    if (campaignId) {
      setSelectCampaign(campaignId);
    }
  }, [campaignId]);

  useEffect(() => {
    if (tabType === "post") {
      fetchPostData();
    } else {
      fetchCommentData();
    }

    fetchcheckPermission();
  }, [filter, sortby, filterStatus, tabType, campaignId]);

  // useEffect(() => {
  //   if (newsData.length && Math.ceil(newsData.length / 10) < currentPage) {
  //     setCurrentPage(1);
  //   }
  // }, [currentPage, newsData.length]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = newsData.slice(startIndex, endIndex);

  const onEditTrend = (news) => {
    if (tabType === "post") {
      navigate("/edit/trend", {
        state: {
          trendId: news?.id,
          title: news?.title,
          poster: news?.poster,
          description: news?.description,
          url: news?.url,
          likes: news?.likes,
          shares: news?.shares,
          comments: news?.comments,
          views: news?.views,
          sentiment: {
            positive_trend: news?.positive_trend,
            negative_trend: news?.negative_trend,
          },
          campaign_id: news?.campaign_id,
          arrow: news?.arrow,
          keyword: news?.keyword,
          platform: news?.platform,
          post_date: news?.post_date,
          type: "edit",
          page: currentPage,
          current_campaign_id: campaignId,
          status: news?.approved,
          image_path: news?.image_path || [],
          image_url: news?.image_url || null,
        },
      });
    } else {
      navigate("/edit/comment", {
        state: {
          trendId: news?.id,
          title: news?.title,
          news_id: news?.news_id,
          description: news?.description,
          url: news?.url,
          likes: news?.likes,
          shares: news?.shares,
          comments: news?.comments,
          views: news?.views,
          sentiment: {
            positive_trend: news?.positive_trend,
            negative_trend: news?.negative_trend,
          },
          campaign_id: news?.campaign_id,
          arrow: news?.arrow,
          keyword: news?.keyword,
          platform: news?.platform,
          post_date: news?.post_date,
          type: "edit",
          page: currentPage,
          current_campaign_id: campaignId,
          status: news?.approved,
        },
      });
    }
  };
  const onAddComment = (data) => {
    setCommentParent(data);
    setIsModalComment(true);
  };

  const showDeleteModal = (id) => {
    setCurretId(id);
    setIsModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (tabType === "post") {
      await listTrendProvider.deleteListTrend(currentId);
      fetchPostData();
    } else {
      await commentProvider.deleteComment(currentId);
      fetchCommentData();
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeStatus = async (id, value) => {
    const body = {
      approved: value,
    };
    if (tabType === "post") {
      await listTrendProvider.editListTrend(id, body);
      fetchPostData();
    } else {
      await commentProvider.editComment(id, body);
      fetchCommentData();
    }
  };

  const getStatusColor = (status) => {
    if (status === 1) {
      return "#0EA67F";
    }
    if (status === 2) {
      return "#7D5DE8";
    }
    return "#FF2643";
  };

  return (
    <Flex gap="small" wrap="wrap" justify="space-between" vertical>
      <StyledDiv>
        <StyledTable>
          <thead>
            <tr>
              {titleData.map((e) => (
                <StyledTableHeader key={e.title}>{e.title}</StyledTableHeader>
              ))}
              <StyledTableHeader />
            </tr>
          </thead>
          <tbody>
            {currentData.map((news, index) => (
              <React.Fragment key={news.id}>
                <StyledHoverRow as={index % 2 === 0 ? StyledEvenRow : "tr"}>
                  <StyledTableCell>
                    <Flex justify="center">
                      <CircleProgressComponent
                        platform={news.platform}
                        positiveValue={news.positive_trend}
                        negativeValue={news.negative_trend}
                      />
                    </Flex>
                  </StyledTableCell>
                  {/* <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <TextTable>{news.campaign_name}</TextTable>
                  </StyledTableCell> */}
                  <StyledTableCell>
                    <TextTable>{news.title}</TextTable>
                  </StyledTableCell>
                  <StyledTableCell>
                    <a
                      href={news.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {news.url}
                    </a>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {formatNumber(news.views)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {formatNumber(news.shares)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {formatNumber(news.likes)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {formatNumber(news.comments)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={keysIcons[news.arrow]}
                      alt={news.platform}
                      style={{
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <StyledStatus
                      color={getStatusColor(news.approved)}
                      value={news.approved}
                      options={optionsStatus}
                      onChange={(val) => handleChangeStatus(news?.id, val)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {moment(news.post_date).format("YYYY-MM-DD")}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {moment(news.updated_date).format("YYYY-MM-DD")}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Flex justify="space-around">
                      <StyledButtonIcon
                        type="text"
                        size="small"
                        hoverColor="orange"
                        onClick={() => showDeleteModal(news?.id)}
                      >
                        <img
                          src={keysIcons.delete}
                          alt={news.platform}
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </StyledButtonIcon>
                      <StyledButtonIcon
                        type="text"
                        size="small"
                        hoverColor="orange"
                        onClick={() => onEditTrend(news)}
                      >
                        <img
                          src={keysIcons.edit}
                          alt={news.platform}
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </StyledButtonIcon>
                      {tabType === "post" && (
                        <StyledButtonIcon
                          type="text"
                          size="small"
                          hoverColor="orange"
                          onClick={() => onAddComment(news)}
                        >
                          <img
                            src={keysIcons.comment}
                            alt={news.platform}
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </StyledButtonIcon>
                      )}
                    </Flex>
                  </StyledTableCell>
                </StyledHoverRow>
              </React.Fragment>
            ))}
          </tbody>
        </StyledTable>
      </StyledDiv>
      <ModalConfirmComponent
        okText="Yes"
        okColor="red"
        open={isModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete this trend?</p>
      </ModalConfirmComponent>
      <Modal
        open={isModalComment}
        onCancel={() => setIsModalComment(false)}
        width="1300px"
        footer={null}
      >
        <InputCommentForm
          data={commentParent}
          state={{
            type: "add",
          }}
          fetchCommentData={fetchCommentData}
          setIsModalComment={setIsModalComment}
          tabType={tabType}
        />
      </Modal>
      {Boolean(newsData.length > 10) && (
        <StyledPagination
          current={currentPage}
          total={newsData.length}
          pageSize={pageSize}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{
            marginTop: "20px",
            textAlign: "center",
          }}
        />
      )}
    </Flex>
  );
};

export default ListTable;
