/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import { useState, useCallback, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const useHookProviderUpload = () => {
  const host = process.env.REACT_APP_API_ENDPOINT;
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const fileInputRef = useRef();

  const handleFileChange = useCallback(
    (event) => {
      const { files } = event.target;
      if (files) {
        const fileArray = Array.from(files).slice(0, 4 - images.length);
        const filePreviews = [];

        fileArray.forEach((file) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviews((prevPreviews) => [...prevPreviews, reader.result]);
          };
          reader.readAsDataURL(file);
          filePreviews.push(file);
        });

        setImages((prevImages) => [...prevImages, ...filePreviews]);
      }
    },
    [images],
  );

  const handleRemoveImage = useCallback(
    (index) => {
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
      const newPreviews = [...previews];
      newPreviews.splice(index, 1);
      setPreviews(newPreviews);

      // Reset file input if no images are left
      if (newImages.length === 0) {
        fileInputRef.current.value = "";
      }
    },
    [images, previews],
  );

  const uploadFile = useCallback(async () => {
    if (images.length === 0) {
      setError("No file selected");
      return;
    }
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("file", image);
    });
    try {
      const response = await axios.post(`${host}/uploads/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${Cookies.get("token")}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setProgress(percentCompleted);
        },
      });
      if (response.status !== 200) {
        throw new Error("Failed to upload file");
      }
      return response.data;
    } catch (err) {
      setError(err.message || "An unknown error occurred");
      return null; // คืนค่า null เมื่อเกิดข้อผิดพลาด
    }
  }, [images]);

  return {
    images,
    previews,
    progress,
    error,
    fileInputRef,
    setPreviews,
    handleFileChange,
    handleRemoveImage,
    uploadFile,
    setImages,
  };
};

export default useHookProviderUpload;
