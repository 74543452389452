import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  key: "2",
  isCollapse: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setKeySidebar: (state, action) => {
      state.key = action.payload.value;
    },
    setIsCollapse: (state) => {
      state.isCollapse = !state.isCollapse;
    },
  },
});

export const { setKeySidebar, setIsCollapse } = sidebarSlice.actions;

export default sidebarSlice.reducer;
